import { createStore } from "vuex";
import { State } from "@/store/state";

const store = createStore<State>({
  state: {
      accessToken: "",
      loginURL: "",
      createProject: {
        complex: {
          entities: []
        },
      },
      loader: false,
      files: [],
      sync: {
        selectedModel: {},
        mappings: {
          fmGuidParameter: {}
        }
      },
    },
  mutations: {
    SET_ACCESS_TOKEN(state, token) {
      state.accessToken = token;
    },
    SET_LOADER(state, loaderVisible) {
      state.loader = loaderVisible;
    },
    SET_FILES_METADATA(state, files) {
      state.files = files;
    },
    SET_SELECTED_MODEL(state, model) {
      state.sync.selectedModel = model;
    },
    SET_FMGUID_PARAMETER(state, parameter) {
      state.sync.mappings.fmGuidParameter = parameter;
    },
  },
  actions: {
    async setAccessToken({ commit }, payload) {
      try {
        commit("SET_ACCESS_TOKEN", payload)
      } catch (error) {
        console.error("Error!!!");
      }
    },
    async setLoader({ commit }, payload) {
      try {
        commit("SET_LOADER", payload);
      } catch (error) {
        console.error("Error setting Loader!!!");
      }
    },
    async setFilesMetadata({ commit }, payload) {
      try {
        commit("SET_FILES_METADATA", payload);
      } catch (error) {
        console.error("Error setting Files!!!");
      }
    },
    async setFMGuidParameter({ commit }, payload){
      try{
        commit("SET_FMGUID_PARAMETER", payload);
      } catch(error) {
        console.error("Error setting FMGuid parameter!!!");
      }
    },
    async setSelectedModel({ commit }, payload){
      try{
        commit("SET_SELECTED_MODEL", payload);
      } catch(error) {
        console.error("Error setting selected BimModel!!!");
      }
    },
  },
  getters: {
    getLoader: (state) => {
      return state.loader;
    },
    getFilesMetadata: (state) => {
      return state.files;
    },
    getFMGuidParameter: (state) => {
      return state.sync.mappings.fmGuidParameter;
    },
    getSelectedModel: (state) => {
      return state.sync.selectedModel;
    },
    getAccessToken: (state) => {
      return state.accessToken;
    },
  }

})
export default store;
