import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16a029ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComplexList = _resolveComponent("ComplexList")!
  const _component_CreateProject = _resolveComponent("CreateProject")!
  const _component_DxPopup = _resolveComponent("DxPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ComplexList, {
      ref: _ctx.complexListRefKey,
      onCreateProject: _ctx.showPopup,
      onGoToDataGrid: _ctx.goToDataGrid
    }, null, 8, ["onCreateProject", "onGoToDataGrid"]),
    _createVNode(_component_DxPopup, {
      visible: _ctx.isAddComplexModalVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isAddComplexModalVisible) = $event)),
      name: "createProjectModal",
      title: _ctx.modalTitle,
      height: "auto",
      "max-width": "1300px",
      "max-height": "800px",
      "content-template": "popup-content",
      "close-on-outside-click": false,
      onHidden: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPopupHiding()))
    }, {
      "popup-content": _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_CreateProject, {
            "popup-visible": _ctx.isAddComplexModalVisible,
            "edit-mode": _ctx.editMode,
            "start-step": _ctx.modalStep,
            "wizard-data": _ctx.wizardData,
            onHideCreatingProjectPopup: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideCreateProjectPopup())),
            onChangeModalTitle: _ctx.changeModalTitle
          }, null, 8, ["popup-visible", "edit-mode", "start-step", "wizard-data", "onChangeModalTitle"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "title"])
  ]))
}