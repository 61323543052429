<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button 
            icon="menu" 
            styling-mode="text" 
            @click="toggleMenuFunc" 
          />
        </template>
      </dx-item>

      <dx-item
        v-if="title"
        location="before"
        css-class="header-title dx-toolbar-label"
      >
        <div>{{ title }}</div>
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="languageMenuItems"
      >
        <template #default>
          <div>
            <dx-button
              class="language-button authorization"
              width="auto"
              height="100%"
              styling-mode="text"
            >
              <language-panel
                :email="email"
                :menu-items="languageMenuItems"
                menu-mode="context"
              />
            </dx-button>
          </div>
        </template>
      </dx-item>
      <template #menuLanguageItem>
        <language-panel
          :email="email"
          :menu-items="languageMenuItems"
          menu-mode="list"
        />
      </template>
      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
        <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              width="auto"
              height="100%"
              styling-mode="text"
            >
              <user-panel
                :email="email"
                :menu-items="userMenuItems"
                menu-mode="context"
              />
            </dx-button>
          </div>
        </template>
      </dx-item>

      <template #menuUserItem>
        <user-panel
          :email="email"
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../authsrc/auth";
import { useRouter, useRoute } from "vue-router";
import { ref } from "vue";
import { formatMessage } from "devextreme/localization";
import UserPanel from "./user-panel";
import LanguagePanel from "./language-panel";

export default {
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel,
    LanguagePanel,
  },
  props: {
    menuToggleEnabled: Boolean,
    title: {
      type: String,
      required: true,
    },
    toggleMenuFunc:{
      type: Function,
      required: true,
    },
    logOutFunc: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const email = ref("");
    auth.getUser().then((e) => email.value = (e.data ? e.data.email : ""));

    const sweFlag = require("@/assets/flags/swe.png");
    const ukFlag = require("@/assets/flags/uk.png");
    const languageMenuItems = [
      {
        text: "English",
        icon: ukFlag,
        onClick: changeLanguageEn,
      },
      {
        text: "Svenska",
        icon: sweFlag,
        onClick: changeLanguageSwe,
      },
    ];

    const userMenuItems = [{
                             text: formatMessage("ReusableProfileLabel"),
                             icon: "user",
                             onClick: onProfileClick
                           },
                           {
                             text: formatMessage("HeaderToolbarLogoutMenuLabel"),
                             icon: "runner",
                             onClick: onLogoutClick
                           }];

    function changeLanguageEn() {
      localStorage.setItem("locale", "en");
      document.location.reload();
    }

    function changeLanguageSwe() {
      localStorage.setItem("locale", "swe");
      document.location.reload();
    }

    function onProfileClick() {
      router.push({
        path: "/profile",
        query: { redirect: route.path }
      });
    }

    function onLogoutClick() {
      auth.logOut();
    }

    return {
      email,
      userMenuItems,
      languageMenuItems
    };
  }
};
</script>

<style scoped lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }

  .screen-x-small & {
    .dx-button-has-icon {
      margin: 0 !important;
    }
  }
  
}
.dx-toolbar-before {
  padding: 0 !important;
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
</style>
