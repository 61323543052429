
import DxPopup from "devextreme-vue/popup";
import CreateProject from "../components/project/CreateProject.vue";
import { formatMessage } from "devextreme/localization";
import ComplexList from "../components/ComplexList.vue";
import { defineComponent } from "vue";

const complexListRefKey = "complex-list"

const typeToStepDict = {
  0: 1,
  1: 2,
  5: 3
};

export default defineComponent({
  components: {
    DxPopup,
    CreateProject,
    ComplexList,
  },
  data() {
    return {
      isAddComplexModalVisible: false,
      modalStep: 0,
      wizardData: {},
      editMode: false,
      modalTitle: formatMessage("ReusableCreateComplexLabel"),
      complexListKey: 0,
      paths: {
        dataGrid: "/data-grid/",
        dataGridAnd3d: "/data-grid-3d/",
        viewer: "/viewer/"
      },
      complexListRefKey,
    };
  },
  methods: {
    showPopup(complex, objectType, editMode) {
      this.modalStep = typeToStepDict[objectType];
      this.wizardData = complex;
      this.isAddComplexModalVisible = true;
      this.editMode = editMode;
      this.changeModalTitle(this.modalStep, editMode);
    },
    onPopupHiding() {
      this.isAddComplexModalVisible = false;
      this.complexListKey++;
      if(this.modalStep > 0){
        this.complexList().reloadData();
      }
    },
    hideCreateProjectPopup() {
      this.isAddComplexModalVisible = false;
    },
    changeModalTitle(modalStep, editMode) {
      this.modalTitle = editMode ? formatMessage("ReusableEdit") : formatMessage("ReusableCreate");
      this.modalTitle += " ";
      this.modalStep = modalStep;
      if (this.modalStep === 2) {
        this.modalTitle += formatMessage("ReusableEntity");
      } else if (this.modalStep === 3) {
        this.modalTitle += formatMessage("ReusableModelsLabel");
      } else {
        this.modalTitle += formatMessage("ReusableComplexLabel");
      }
    },
    goToDataGrid(modelList, include3d, archiveVisible) {
      this.$router.push({
        path: include3d ? this.paths.dataGridAnd3d : this.paths.dataGrid,
        query: {
          models: modelList,
          showExpired: archiveVisible
        },
      });

    },
    formatMessage,
    complexList: function() {
      return this.$refs[this.complexListRefKey];
    },
  },
});
