import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97cb5b22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-block" }
const _hoisted_2 = {
  id: "settingsView",
  class: "settings-view"
}
const _hoisted_3 = { class: "settings-menu" }
const _hoisted_4 = { class: "dx-card settings-group responsive-paddings" }
const _hoisted_5 = { class: "dx-form-group-caption" }
const _hoisted_6 = { class: "settings-list" }
const _hoisted_7 = { class: "settings-menu" }
const _hoisted_8 = { class: "dx-card settings-group responsive-paddings" }
const _hoisted_9 = { class: "dx-form-group-caption" }
const _hoisted_10 = { class: "settings-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxButton = _resolveComponent("DxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.parametersLabel), 1),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parametersItems, (item, index) => {
              return (_openBlock(), _createBlock(_component_DxButton, {
                key: index,
                icon: item.icon,
                text: _ctx.getTranslate(item.text),
                disabled: !_ctx.isPermitted(item.text),
                onClick: ($event: any) => (_ctx.onActionClick(item.text))
              }, null, 8, ["icon", "text", "disabled", "onClick"]))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.administratorLabel), 1),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settingsItems, (item, index) => {
              return (_openBlock(), _createBlock(_component_DxButton, {
                key: index,
                icon: item.icon,
                text: _ctx.getTranslate(item.text),
                disabled: !_ctx.isPermitted(item.text),
                onClick: ($event: any) => (_ctx.onActionClick(item.text))
              }, null, 8, ["icon", "text", "disabled", "onClick"]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}