import auth from "./authsrc/auth";
import { createRouter, createWebHashHistory } from "vue-router";
import Home from "./views/home.vue";
import Files from "./views/files.vue";
import Settings from "./views/settings/settings.vue";
import ParameterList from "./views/settings/parameter-list.vue";
import ParameterGroups from "./views/settings/parameter-groups.vue";
import UserList from "./views/settings/user-list.vue";
import PermissionList from "./views/settings/permission-list.vue";
import Mappings from "./views/mapping.vue";
import Profile from "./views/profile.vue";
import defaultLayout from "./layouts/side-nav-outer-toolbar.vue";
import simpleLayout from "./layouts/single-card.vue";
import sideNavSimple from "./layouts/side-nav-simple.vue";
import DataGridView from "./views/data-grid.vue";
import Viewer from "./views/viewer.vue";
import DataGrid3DView from "./views/data-and-3d.vue";

function loadView(view) {
  return () => import(`./views/${view}.vue`)
}

const router = createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/ifcfiles",
      name: "ifcfiles",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Files
    },
    {
      path: "/settings",
      name: "settings",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Settings
    },
    {
      path: "/settings/parameter-list",
      name: "parameter-list",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      beforeEnter: (to, from) =>
      {
        if (!auth.getPermissions().parameters.canView) {
          return from.fullPath;
        }
      },
      component: ParameterList
    },
    {
      path: "/settings/parameter-groups",
      name: "parameter-groups",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      beforeEnter: (to, from) =>
      {
        if (!auth.getPermissions().parameterGroups.canView) {
          return from.fullPath;
        }
      },
      component: ParameterGroups
    },
    {
      path: "/settings/user-list",
      name: "user-list",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      beforeEnter: (to, from) =>
      {
        if (!auth.getPermissions().users.canView) {
          return from.fullPath;
        }
      },
      component: UserList
    },
    {
      path: "/settings/permission-list",
      name: "permission-list",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      beforeEnter: (to, from) =>
      {
        if (!auth.getPermissions().users.canView) {
          return from.fullPath;
        }
      },
      component: PermissionList
    },
    {
      path: "/mappings",
      name: "mappings",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      beforeEnter: (to, from) =>
      {
        if (!auth.getPermissions().ifc.canValidate) {
          return from.fullPath;
        }
      },
      component: Mappings
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/data-grid",
      name: "data-grid",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DataGridView,
      beforeEnter: (to, from) =>
      {
        if (!auth.getPermissions().dataGrid.canView) {
          return from.fullPath;
        }
      }
    },
    {
      path: "/data-grid-3d",
      name: "data-grid-3d",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DataGrid3DView,
      beforeEnter: (to, from) =>
      {
        if (!auth.getPermissions().dataGrid.canView || !auth.getPermissions().threeDimensional.canView) {
          return from.fullPath;
        }
      }
    },
    {
      path: "/viewer",
      name: "viewer",
      meta: {
        requiresAuth: true,
        layout: sideNavSimple
      },
      component: Viewer,
      beforeEnter: (to, from) =>
      {
        if (!auth.getPermissions().threeDimensional.canView) {
          return from.fullPath;
        }
      }
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    },
    {
      path: "/login-page",
      name: "login-page",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-page")
    }
  ],
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {
  if (to.name === "login-page" && auth.loggedIn()) {
    next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-page",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
