<template>
  <br>
  <br>
  <br>
  <br>
  <div class="comparison-results-container">
    <div>
      <DxDataGrid
        id="comparisonResultsSummaryGrid"
        :key="failedGridKey"
        class="dx-form-group-content"
        width="900px"
        :data-source="results"
        :show-borders="false"
        :allow-column-resizing="true"
        :allow-column-reordering="true"
        :column-resizing-mode="'nextColumn'"
        :column-min-width="15"
        :column-auto-width="true"
        :repaint-changes-only="true"
        @cell-prepared="onCellPrepared"
      >
        <DxSearchPanel :visible="false" />
        <DxPaging :enabled="false" />
        <DxColumn 
          :allow-sorting="true"
          data-field="ifcTypeValue"
          :caption="getTranslate('IfcTypeValue')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="totalOld"
          :caption="getTranslate('TotalOld')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="totalNew"
          :caption="getTranslate('TotalNew')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="_new"
          css-class="greenColumn"
          :caption="getTranslate('New')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="changed"
          css-class="amberColumn"
          :caption="getTranslate('Changed')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="unchanged"
          css-class="grayColumn"
          :caption="getTranslate('Unchanged')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="deleted"
          css-class="redColumn"
          :caption="getTranslate('Deleted')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="revived"
          css-class="blueColumn"
          :caption="getTranslate('Revived')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import { formatMessage } from "devextreme/localization";

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPaging,
} from "devextreme-vue/data-grid";

export default {
  name: "ComparisonResults",
  components: {
    DxSearchPanel,
    DxDataGrid,
    DxColumn,
    DxPaging,
  },
  props:  {
    comparisonResultsProp: {
      type:  Function,
      required: true,
    }
  },
  emits: [],
  data() {
    return {
      results: []
    }
  },
  watch: {
    comparisonResultsProp: {
      immediate: true,
      handler (comparisonResults) {
        this.results = comparisonResults;
        store.dispatch("setLoader", false);
      }
    }
  },
  methods: {
    getTranslate(text) {
      return formatMessage(text);
    },
  }
}
</script>
<style scoped>
  .comparison-results-container{
    margin-top:20px;
  }

:global(.dx-header-row .greenColumn) {
  background-color: #a9d08e;
}

:global(.dx-header-row .redColumn) {
  background-color: #ff0000;
}

:global(.dx-header-row .amberColumn) {
  background-color: #ed7d31;
}

:global(.dx-header-row .grayColumn) {
  background-color: #aeaaaa;
}

:global(.dx-header-row .blueColumn) {
  background-color: #00b0f0;
}

:global(.greenColumn) {
  background-color: #e2efda;
  color:black;
}

:global(.redColumn) {
  background-color: #f76f73;
  color:black;
}

:global(.amberColumn) {
  background-color: #f4b084;
  color:black;
}

:global(.grayColumn) {
  background-color: #cccccc;
  color:black;
}

:global(.blueColumn) {
  background-color: #85c9e8;
  color:black;
}

</style>
