import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-block" }
const _hoisted_2 = { class: "dx-field-item-label-text" }
const _hoisted_3 = { class: "dx-field-item-label-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxFileUploader = _resolveComponent("DxFileUploader")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxPopup = _resolveComponent("DxPopup")!
  const _component_DxItem = _resolveComponent("DxItem")!
  const _component_DxLabel = _resolveComponent("DxLabel")!
  const _component_DxForm = _resolveComponent("DxForm")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxPaging = _resolveComponent("DxPaging")!
  const _component_DxPager = _resolveComponent("DxPager")!
  const _component_DxFilterPanel = _resolveComponent("DxFilterPanel")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_DxTagBox = _resolveComponent("DxTagBox")!
  const _component_DxCheckBox = _resolveComponent("DxCheckBox")!
  const _component_DxSwitch = _resolveComponent("DxSwitch")!
  const _component_DxToolbar = _resolveComponent("DxToolbar")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString($options.getTranslate("ListParameters")), 1),
    _createElementVNode("div", null, [
      _createVNode(_component_DxButton, {
        id: "templateButton",
        class: "button actionButton",
        text: $data.labels.excelTemplateLabel,
        onClick: $options.exportListTemplate
      }, null, 8, ["text", "onClick"]),
      _createVNode(_component_DxButton, {
        id: "exportButton",
        class: "button actionButton",
        text: $data.labels.exportExcelLabel,
        onClick: $options.onExportParametersList
      }, null, 8, ["text", "onClick"]),
      _createVNode(_component_DxFileUploader, {
        id: "importButton",
        class: "uploader-like-button actionButton",
        "select-button-text": $data.labels.importExcelLabel,
        accept: ".xlsx",
        text: $data.labels.importExcelLabel,
        "label-text": null,
        "upload-mode": "useButton",
        "show-file-list": false,
        disabled: !$data.permissions.parameters.canEdit,
        onValueChanged: $options.onImportParametersList
      }, null, 8, ["select-button-text", "text", "disabled", "onValueChanged"])
    ]),
    _createVNode(_component_DxDataGrid, {
      id: "parametersListGrid",
      ref: $data.parametersListRefKey,
      "data-source": $data.parameters,
      "show-borders": false,
      "column-resizing-mode": 'nextColumn',
      "column-min-width": 150,
      "column-auto-width": true,
      onInitNewRow: $options.onInitNewRow,
      onEditingStart: $options.onEditingStart,
      onEditCanceling: $options.onEditCanceling,
      onOptionChanged: $options.onOptionChanged,
      onEditorPreparing: $options.onEditorPreparing,
      onExporting: _ctx.onExporting,
      onRowPrepared: $options.onRowPrepared
    }, {
      sourceNameRow: _withCtx(({ data }) => [
        _createVNode(_component_DxTagBox, {
          value: data.key.sourceNames,
          disabled: true
        }, null, 8, ["value"])
      ]),
      sourceNameEditor: _withCtx(({ data }) => [
        _createVNode(_component_DxTagBox, {
          value: data.key.sourceNames,
          "on-value-changed": (value) => $options.onParameterSourceNameValueChanged(value, data),
          "accept-custom-value": true,
          "open-on-field-click": false
        }, null, 8, ["value", "on-value-changed"])
      ]),
      lookupRow: _withCtx(({ data }) => [
        _createVNode(_component_DxCheckBox, {
          value: !!data.key.parameter?.rule?.ruleValues,
          disabled: true
        }, null, 8, ["value"])
      ]),
      lookupEditor: _withCtx(({ data }) => [
        _createVNode(_component_DxTagBox, {
          ref: $data.ruleValuesLookupRefKey,
          visible: $data.lookupValuesVisible,
          value: data.key.parameter?.rule?.ruleValues,
          "value-expr": data.key.parameter?.rule?.ruleValues?.ruleValueId,
          "display-expr": $options.displayExpressionLookupValue,
          "accept-custom-value": true,
          "open-on-field-click": false,
          onValueChanged: (event) => $options.onLookupValueChanged(event, data),
          onCustomItemCreating: $options.onCustomLookupValueCreating
        }, null, 8, ["visible", "value", "value-expr", "display-expr", "onValueChanged", "onCustomItemCreating"])
      ]),
      switchEditor: _withCtx(({ data }) => [
        _createVNode(_component_DxSwitch, {
          value: (data.key.parameter?.rule?.setRule === 1),
          "on-value-changed": (event) => $options.onLookupSwitchChanged(event, data)
        }, null, 8, ["value", "on-value-changed"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DxSearchPanel, { visible: true }),
        _createVNode(_component_DxEditing, {
          mode: "popup",
          "allow-updating": $data.permissions.parameters.canEdit,
          "allow-deleting": $data.permissions.parameters.canDelete,
          "allow-adding": $data.permissions.parameters.canCreate
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxPopup, {
              "show-title": true,
              title: $data.labels.createParameterLabel,
              "max-width": 700,
              "max-height": 550
            }, null, 8, ["title"]),
            _createVNode(_component_DxForm, { "col-count": 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_DxItem, {
                  "col-span": 6,
                  "data-field": "parameter.name",
                  disabled: $options.isSystemParameter()
                }, null, 8, ["disabled"]),
                _createVNode(_component_DxItem, {
                  "col-span": 6,
                  "data-field": "parameter.dataType",
                  disabled: $options.isSystemParameter()
                }, null, 8, ["disabled"]),
                _createVNode(_component_DxItem, {
                  "col-span": 12,
                  "data-field": "sourceNames",
                  disabled: $options.CannotBeMappedParameter()
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxLabel, {
                      text: $options.getTranslate('SourceNamesConfirm')
                    }, null, 8, ["text"])
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_DxItem, {
                  "col-span": 1,
                  "data-field": "parameter.rule.setRule",
                  disabled: $options.isSystemParameter()
                }, null, 8, ["disabled"]),
                _createVNode(_component_DxItem, {
                  "col-span": 11,
                  "data-field": "parameter.rule.ruleValues",
                  disabled: $options.isSystemParameter()
                }, null, 8, ["disabled"]),
                _createVNode(_component_DxItem, { "col-span": 3 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_2, _toDisplayString($data.labels.importExcelLabel), 1),
                    _createVNode(_component_DxFileUploader, {
                      class: "uploader-like-button",
                      "select-button-text": $data.labels.selectFileLabel,
                      accept: ".xlsx",
                      disabled: !$data.lookupValuesVisible,
                      text: $data.labels.selectFileLabel,
                      "upload-mode": "useButton",
                      "show-file-list": false,
                      onValueChanged: $options.onImportFile
                    }, null, 8, ["select-button-text", "disabled", "text", "onValueChanged"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_DxItem, { "col-span": 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString($data.labels.exportExcelLabel), 1),
                    _createVNode(_component_DxButton, {
                      class: "button",
                      text: $data.labels.exportExcelLabel,
                      disabled: !$data.lookupValuesVisible,
                      onClick: $options.exportLookupValues
                    }, null, 8, ["text", "disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["allow-updating", "allow-deleting", "allow-adding"]),
        _createVNode(_component_DxPaging, { "page-size": 25 }),
        _createVNode(_component_DxPager, {
          "allowed-page-sizes": [25, 50, 100, 200],
          "display-mode": "full",
          "show-page-size-selector": true,
          "show-info": true,
          "show-navigation-buttons": true,
          visible: true,
          "info-text": $data.labels.reusablePaginationLabel
        }, null, 8, ["info-text"]),
        _createVNode(_component_DxFilterPanel, { visible: true }),
        _createVNode(_component_DxHeaderFilter, {
          "allow-search": true,
          visible: true
        }),
        _createVNode(_component_DxFilterRow, {
          visible: true,
          "apply-filter": 'auto'
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "parameter.name",
          caption: $options.getTranslate('ReusableNameLabel')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "parameter.dataType",
          caption: $options.getTranslate('DataType')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxLookup, {
              "data-source": $data.propertyDataType,
              "value-expr": "id",
              "display-expr": "name"
            }, null, 8, ["data-source"])
          ]),
          _: 1
        }, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "sourceNames",
          "cell-template": "sourceNameRow",
          "edit-cell-template": "sourceNameEditor",
          caption: $options.getTranslate('SourceNames')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          name: "calculatedRuleValue",
          "calculate-cell-value": $options.calculateValueForRuleValues,
          caption: $data.labels.reusableLookupValuesLabel,
          "allow-filtering": true
        }, null, 8, ["calculate-cell-value", "caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "parameter.rule.ruleValues",
          "cell-template": "lookupRow",
          "edit-cell-template": "lookupEditor",
          caption: $data.labels.reusableLookupValuesLabel,
          visible: false
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "parameter.rule.setRule",
          caption: $data.labels.reusableLookupLabel,
          "edit-cell-template": "switchEditor",
          "data-type": "boolean",
          visible: false
        }, null, 8, ["caption"]),
        _createVNode(_component_DxToolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_DxItem, {
              options: $data.addParameterButtonOptions,
              name: "addRowButton",
              "show-text": "always",
              onClick: $options.onAddParameterClick
            }, null, 8, ["options", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data-source", "onInitNewRow", "onEditingStart", "onEditCanceling", "onOptionChanged", "onEditorPreparing", "onExporting", "onRowPrepared"])
  ]))
}