const locales = [
  {
    Name: "English",
    Value: "en",
  },
  {
    Name: "Swedish",
    Value: "swe",
  },
];

const dictionary = {
  en: {
    Add: "Add",
    AnErrorHasOccurred: "An error has occurred",
    SideNavMenuHomeLabel: "Home",
    SideNavMenuExampleLabel: "Examples",
    SideNavMenuTasksLabel: "Tasks",
    SideNavMenuTasksFiles: "Files",
    ReusableEntitiesLabel: "Entities",
    ReusableProfileLabel: "Profile",
    ReusablePaginationLabel: "Page {0} of {1} ({2} items)",
    ReusableNameLabel: "Name",
    ReusableComplexLabel: "Complex",
    ReusableBackButtonLabel: "Back",
    ReusableNextButtonLabel: "Next",
    ReusableCancelButtonLabel: "Cancel",
    ReusableUploadButtonLabel: "Upload",
    ReusableComplexInputNamePlaceholder: "Complex Name",
    ReusableEntityInputNamePlaceholder: "Entity Name",
    ReusableCreateComplexLabel: "CREATE COMPLEX",
    ReusableModelsLabel: "Models",
    ReusableComplexIdLabel: "Complex ID",
    ReusableEntityIdLabel: "Entity ID",
    ReusableModelNameLabel: "Model Name",
    ReusableIdLabel: "ID",
    ReusableGuidLabel: "GUID",
    ReusableFmGuidLabel: "FMGUID",
    ReusableLookupLabel: "Lookup",
    ReusableLookupValuesLabel: "Lookup Values",
    ReusableNoDataInListLabel: "No Data Available",
    ReusableRevision: "Revision",
    ReusableDataGrid: "Data Grid",
    ReusableDataGridAnd3d: "Data & 3D",
    ReusableUploadFailed: "Upload failed",
    ReusableDraftChangesValidationFail: "Validation failed",
    ReusableSaveFailed: "Save failed",
    ReusableCancelFailed: "Cancel failed",
    ReusableSavingChanges: "Saving changes...",
    ReusableAddingChanges: "Adding changes...",
    ReusableCancellingChanges: "Cancelling changes...",
    ReusableOKButtonLabel: "OK",
    ReusableCreate: "Create",
    ReusableEdit: "Edit",
    ReusableEntity: "Entity",
    ReusableClose: "Close",
    ReusablePublish: "Publish",
    ReusableCancelDraft: "Cancel the draft",
    ReusableExpiredDate: "Expired date",
    CreateProjectModalAddModelButtonLabel: "Add",
    CreateProjectModalInputNameRequiredText: "Name is required",
    CreateProjectModalInputGuidWrongFormatText: "The guid is not in valid format!",
    LanguagePanelMenuLabel: "Languages",
    HeaderToolbarLogoutMenuLabel: "Logout",
    AppFooterLabel: "All trademarks or registered trademarks are property of their respective owners.",
    AppFooterCopyrighLabel: "Copyright",
    FileStatus: "File status",
    FileStatusUploaded: "Uploaded",
    FileStatusLaunchContainer: "Launch Container",
    FileStatusProcessingObjects: "Processing Objects",
    FileStatusProcessingPropertiesAndGeometries: "Processing Properties And Geometries",
    FileStatusFailed: "Failed",
    FileStatusFailedValidation: "Failed Validation",
    FileStatusSucceeded: "Succeeded",
    FileStatusDeleted: "Deleted",
    FileStatusGenerating: "Generating",
    DownloadFile: "Download",
    DownloadIfc: "Download as IFC",
    ShowExpired: "Show expired",
    Size: "Size",
    FileID: "File ID",
    DateUploaded: "Date Uploaded",
    FileType: "Process Type",
    Progress: "Progress",
    ErrorLog: "Log",
    Validate: "Validate",
    GetFilesError: "Error getting files metadata!",
    ShowActiveFiles: "Active",
    ShowInactiveFiles: "Active",
    DownloadLogsError: "Error downloading logs!",
    FileTypeIFC: "IFC",
    FileTypeUnknown: "Unknown",
    SelectFilter: "Select filter",
    FilterNamePrompt: "Enter new filter name",
    FilterNamePlaceholder: "Enter filter name",
    AvailableCategories: "Available categories",
    SelectedCategories: "Selected categories",
    Proceed: "Proceed",
    ExternalParameters: "External Parameters",
    ExternalParameterSets: "External Parameter Sets",
    IsLookup: "Is lookup",
    AssetParameters: "Asset Parameters",
    Actions: "Actions",
    Mappings: "Mappings",
    Required: "Required",
    Unique: "Unique",
    Type: "Type",
    Save: "Save",
    RemoveMappings: "Remove all",
    ConfirmRemovingAll: "Are you sure you want to remove all?",
    Yes: "Yes",
    No: "No",
    Mapped: "Mapped",
    Unmapped: "Unmapped",
    BimObjectName: "Bimobject name",
    BimObjectExternalId: "Bimobject external ID",
    ResultText: "Result text",
    DataSummary:"Data Summary",
    New: "New",
    Changed:"Changed",
    Unchanged:"Unchanged",
    Revived:"Revived",
    Deleted:"Deleted",
    TotalNew: "Total IFC",
    TotalOld: "Total DB",
    ValidationResults: "Validation Results",
    FailsPerRuleset: "Fails Per Ruleset",
    FailingValue: "Failing Value",
    RulesetName: "Rulset name",
    Finalise: "Finalise",
    FailedObjects: "Failed Objects",
    ConfirmDeletingImport: "Are you sure you want to delete?",
    RulesetFail: "Ruleset Fail",
    TotalObjectCount: "Objects with a failing property",
    TotalPropertyCount: "Properties Validated",
    PropertyPass: "Passed",
    PropertyFail: "Failed",
    FinaliseSucceeded: "Finalise Succeeded!",
    FinaliseError: "Finalise broken because of error!",
    Delete: "Delete",
    Refresh: "Refresh",
    SideNavMenuTasksSettings: "Settings",
    Parameters: "Parameters",
    Administrator: "Administrator",
    Parameter: "Parameter",
    CreateParameter: "Add Parameter",
    EditParameter: "Edit Parameter",
    ListParameters: "Parameter list",
    ParameterGroups: "Parameter groups",
    Color: "Color",
    CreateParameterGroup: "Create parameter group",
    EditParameterGroup: "Edit parameter group",
    CreateEditParameterGroupNameRequiredMessage: "Parameter group name is required",
    CreateEditParameterGroupNameLengthMessage: "Parameter group name can be max {0} characters long",
    AvailableParameters: "Available parameters",
    ListUsers: "Users list",
    ListPermissions: "Permissions list",
    ImportExcel: "Import excel",
    ExportExcel: "Export excel",
    ExcelTemplate: "Excel template",
    DataType: "Data Type",
    IsInValues: "Lookup values",
    ObjectType: "Object Type",
    IfcTypeValue: "IFC Type Value",
    Error: "Error",
    CreateParameterValidationFailed: "Parameter validation failed",
    Success: "Success!",
    IsFmGuid: "Is FMGuid",
    Rules: "Rules",
    RevisionId: "Revision Id",
    BimObjectId: "BimObject Id",
    Designation: "Designation",
    CommonName: "Common name",
    ExternalId: "External Id",
    ComplexFMGuid: "Complex ID",
    ComplexDesignation: "Complex Designation",
    ComplexCommonName: "Complex Common Name",
    EntityFMGuid: "Entity ID",
    EntityDesignation: "Entity Designation",
    EntityCommonName: "Entity Common Name",
    ModelId: "Model ID",
    ModelDisciplineID: "Model Discipline ID",
    ModelName: "Model Name",
    LevelDesignation: "Level Designation",
    LevelCommonName: "Level Common Name",
    LevelFmGuid:"Level FmGuid",
    LevelBimObjectId:"Level BimObjectId",
    InRoomDesignation: "In Room Number",
    InRoomCommonName: "In Room Name",
    InRoomBimObjectId: "In Room BimObjectId",
    InRoomFmGuid:"In Room FmGuid",
    LevelName:"Level Name",
    LevelNumber:"Level Number",
    RoomName:"Room Name",
    RoomNumber:"Room Number",
    FromRoomDesignation: "From Room Number",
    FromRoomCommonName: "From Room Name",
    ToRoomDesignation: "To Room Number",
    ToRoomCommonName: "To Room Name",
    PropertyName: "Property Name",
    SourceNames: "Source Names",
    SourceNamesConfirm: "Source Names (Confirm new value by pressing Enter)",
    SelectFile: "Select File",
    ViewAll: "View All",
    HideFiltered: "Hide Filtered",
    XRayFiltered: "XRay Filtered",
    Show: "Show",
    Hide: "Hide",
    ViewFit: "View Fit",
    Object: "Object",
    Others: "Others",
    All: "All",
    SelectedItems: "Selected Items",
    NotSelectedItems: "Not Selected Items",
    XRay: "X-Ray",
    None: "None",
    ResetSlices: "Reset Slices",
    ViewInSpace: "View In Space",
    RevertRowSingle: "Revert row (1)",
    RevertRowMultiple: "Revert rows ({0})",
    RevertCell: "Revert cell",
    SelectedRowSingle: "You have 1 row selected",
    SelectedRowMultiple: "You have %SELNO% rows selected",
    DeleteCellValue: "Delete cell value",
    SelectNone: "Select None",
    SelectParent: "Select Parent",
    DifferentValues: "Different Values",
    EditRowSingle: "Edit Row (1)",
    EditRowMultiple: "Edit Rows ({0})",
    ExpireRowSingle: "Expire row (1)",
    ExpireRowMultiple: "Expire rows ({0})",
    FormEditRowSingle: "Editing Row (1)",
    FormEditRowMultiple: "Editing Rows",
    BulkUpdatingData: "Updating bulk data",
    OnlyContainNumbers: "This should only contain numbers",
    ObjectDetails: "Object details",
    DateCreated: "Date Created",
    DateModified: "Date Modified",
    DateExpired: "Date Expired",
    CreatedInModel: "Created In Model",
    ComplexValidationFailed: "Validation failed for object: ",
    ComplexCreateFailed: "Error creating object: ",
    FileAlreadyExists: "File already exists: ",
    FileUploadFailedNoFile: "No file was uploaded for model: ",
    InvalidFile: "The file is invalid",
    ColumnPicker: "Column Picker",
    MissingColumnNamesInTheFirstRow: "Missing column names in the first row: {0}.",
    ImportParametersListValidationErrorSingularForm: "There is {0} error in the {1} column.",
    ImportParametersListValidationErrorPluralForm: "There are {0} errors in the {1} column.",
    WarningAboutMissingFile: "Please either upload IFC for all modeles or click SAVE to continue without uploading.",
    WarningComplexWasCreated: "Complex: {0} was created.",
    WarningEntityWasCreated: "Entity: {0} was created.",
    WarningEntitiesWereCreated: "Entities: {0} were created.",
    WarningModelWasCreated: "Model: {0} was created.",
    WarningAfterCancellingDraft: "Changes in metadata is saved. E.g. name of Complex, Entity or Model.",
    GridUsersCreationDate: "First invitation date",
    GridUsersEmailVerified: "Accepted",
    GridUsersActive: "Active",
    GridUsersRole: "Role",
    GridUsersEditUser: "Edit user",
    GridUsersInviteUser: "Invite user",
    GridUsersEmail: "Email",
    GridUsersFirstName: "First name",
    GridUsersLastName: "Last name",
    GridUsersReinvite: "Reinvite",
    GridPermissionsName: "Permission",
    GridPermissionsViewer: "Viewer",
    GridPermissionsEditor: "Editor",
    GridPermissionsAdmin: "Admin",
    AuthenticationFailed: "Authentication failed",
    UserIsNotAcceptedYet: "Account is not accepted yet",
    NewLabel: "New",
    DraftLabel: "Draft",
    ShowArchiveObjects: "Archive",
    ObjectsNotOriginatedInIFC: "Objects that did not originate in the IFC will not be part of the exported IFC",
    NoDataToRevertRows: "Selected rows do not have data to revert.",
    NoDataToRevertRow: "Selected row does not have data to revert.",
    PublishingData:"Data is being published. Please refresh (F5) to check if it is done.",
    NoData:"No Data",
    ClearView: "Clear view",
    DataGridSelectedViewListPlaceholder: "Select a view",
    ViewListMenu: "View List Menu",
    CreateView: "Create view",
    DeleteView: "Delete view",
    UpdateView: "Update view",
    DeleteViewPrompt: "The view is currently active. Delete?",
    ReusableOK: "OK",
    ViewListNamePlaceholder: "Enter view name",
    ViewListNameRequiredRule: "Name is required",
    ViewUpdatedSuccessfully: "View updated successfully",
    ViewCreatedSuccessfully: "View created successfully",
    ViewListNamePatternRule: "Only alphanumeric characters and the following are allowed: [ ] _  -  +  !  @  ( ) : , / \\ and space",
    IntegerValidationMessage:"Only integer values are allowed",
    IFCFileNameValidationRuleForCloud:"Only these Special Characters are allowed in IFC file name  +.#_-,;()'%~$@",
    ParameterAlreadyExists:"Parameter {0} already exists.",
    PartialSuccess:"One or more parameters have failed validation."

  },
  swe: {
    Cancel: "Avbryt",
    Close: "Stäng",
    Clear: "Rensa",
    Done: "Klar",
    Loading: "Laddar...",
    Select: "Välj...",
    Search: "Sök",
    Back: "Bakåt",
    OK: "OK",

    "dxCollectionWidget-noDataText": "Ingen data att visa",

    "dxDropDownEditor-selectLabel": "Välj",

    "validation-required": "Krävs",
    "validation-required-formatted": "{0} krävs",
    "validation-numeric": "Värdet måste vara ett nummer",
    "validation-numeric-formatted": "{0} måste vara ett nummer",
    "validation-range": "Värdet utanför tillåtet intervall",
    "validation-range-formatted": "{0} utanför tillåtet intervall",
    "validation-stringLength": "Längden på värdet är inte korrekt",
    "validation-stringLength-formatted": "Längden på {0} är inte korrekt",
    "validation-custom": "Ogiltigt värd",
    "validation-custom-formatted": "{0} är ogiltigt",
    "validation-async": "Ogiltigt värd",
    "validation-async-formatted": "{0} är ogiltigt",
    "validation-compare": "Värdena matchar inte",
    "validation-compare-formatted": "{0} matchar inte",
    "validation-pattern": "Värdet matchar inte mönster",
    "validation-pattern-formatted": "{0} matchar inte mönster",
    "validation-email": "E-post är ogiltigt",
    "validation-email-formatted": "{0} är ogiltigt",
    "validation-mask": "Värdet är ogiltigt",

    "dxLookup-searchPlaceholder": "Minst antal tecken: {0}",

    "dxList-pullingDownText": "Dra nedåt för att uppdatera...",
    "dxList-pulledDownText": "Släpp för att uppdatera...",
    "dxList-refreshingText": "Uppdaterar...",
    "dxList-pageLoadingText": "Laddar...",
    "dxList-nextButtonText": "Mer",
    "dxList-selectAll": "Välj alla",
    "dxListEditDecorator-delete": "Radera",
    "dxListEditDecorator-more": "Mer",

    "dxScrollView-pullingDownText": "Dra nedåt för att uppdatera...",
    "dxScrollView-pulledDownText": "Släpp för att uppdatera...",
    "dxScrollView-refreshingText": "Uppdaterar...",
    "dxScrollView-reachBottomText": "Laddar...",

    "dxDateBox-simulatedDataPickerTitleTime": "Välj tid",
    "dxDateBox-simulatedDataPickerTitleDate": "Välj datum",
    "dxDateBox-simulatedDataPickerTitleDateTime": "Välj datum och tid",
    "dxDateBox-validation-datetime": "Värdet måste vara ett datum eller en tid",

    "dxRangeSlider-ariaFrom": "Från",
    "dxRangeSlider-ariaTill": "Till",
    "dxSwitch-switchedOnText": "PÅ",
    "dxSwitch-switchedOffText": "AV",

    "dxForm-optionalMark": "valfri",
    "dxForm-requiredMessage": "{0} krävs",

    "dxNumberBox-invalidValueMessage": "Värdet måste vara ett nummer",
    "dxNumberBox-noDataText": "Ingen data",

    "dxDataGrid-columnChooserTitle": "Kolumnväljare",
    "dxDataGrid-columnChooserEmptyText": "Dra en kolumn hit för att dölja den",
    "dxDataGrid-groupContinuesMessage": "Fortsätter på nästa sida",
    "dxDataGrid-groupContinuedMessage": "Fortsättning från föregående sida",
    "dxDataGrid-groupHeaderText": "Gruppera på denna kolumn",
    "dxDataGrid-ungroupHeaderText": "Rensa gruppering",
    "dxDataGrid-ungroupAllText": "Rensa gruppering för alla",
    "dxDataGrid-editingEditRow": "Redigera",
    "dxDataGrid-editingSaveRowChanges": "Spara",
    "dxDataGrid-editingCancelRowChanges": "Avbryt",
    "dxDataGrid-editingDeleteRow": "Radera",
    "dxDataGrid-editingUndeleteRow": "Ångra radering",
    "dxDataGrid-editingConfirmDeleteMessage": "Är du säker på att du vill radera denna post?",
    "dxDataGrid-validationCancelChanges": "Avbryt ändringar",
    "dxDataGrid-groupPanelEmptyText": "Dra en kolumnrubrik hit för att gruppera på den kolumnen",
    "dxDataGrid-noDataText": "Ingen data",
    "dxDataGrid-searchPanelPlaceholder": "Sök...",
    "dxDataGrid-filterRowShowAllText": "(Allt)",
    "dxDataGrid-filterRowResetOperationText": "Återställ",
    "dxDataGrid-filterRowOperationEquals": "Lika med",
    "dxDataGrid-filterRowOperationNotEquals": "Inte lika med",
    "dxDataGrid-filterRowOperationLess": "Mindre än",
    "dxDataGrid-filterRowOperationLessOrEquals": "Mindre än eller lika med",
    "dxDataGrid-filterRowOperationGreater": "Större än",
    "dxDataGrid-filterRowOperationGreaterOrEquals": "Större än eller lika med",
    "dxDataGrid-filterRowOperationStartsWith": "Börjar med",
    "dxDataGrid-filterRowOperationContains": "Innehåller",
    "dxDataGrid-filterRowOperationNotContains": "Innehåller inte",
    "dxDataGrid-filterRowOperationEndsWith": "Slutar med",
    "dxDataGrid-filterRowOperationBetween": "Mellan",
    "dxDataGrid-filterRowOperationBetweenStartText": "Start",
    "dxDataGrid-filterRowOperationBetweenEndText": "Slut",
    "dxDataGrid-applyFilterText": "Använd filter",
    "dxDataGrid-trueText": "sant",
    "dxDataGrid-falseText": "falskt",
    "dxDataGrid-sortingAscendingText": "Sortera stigande",
    "dxDataGrid-sortingDescendingText": "Sortera fallande",
    "dxDataGrid-sortingClearText": "Rensa sortering",
    "dxDataGrid-editingSaveAllChanges": "Spara ändringar",
    "dxDataGrid-editingCancelAllChanges": "Ångra ändringar",
    "dxDataGrid-editingAddRow": "Lägg till rad",
    "dxDataGrid-summaryMin": "Min: {0}",
    "dxDataGrid-summaryMinOtherColumn": "Minimi av {1} är {0}",
    "dxDataGrid-summaryMax": "Max: {0}",
    "dxDataGrid-summaryMaxOtherColumn": "Maximi av {1} är {0}",
    "dxDataGrid-summaryAvg": "Medel: {0}",
    "dxDataGrid-summaryAvgOtherColumn": "Medeltal av {1} är {0}",
    "dxDataGrid-summarySum": "Sum: {0}",
    "dxDataGrid-summarySumOtherColumn": "Summan av {1} är {0}",
    "dxDataGrid-summaryCount": "Antal: {0}",
    "dxDataGrid-columnFixingFix": "Lås",
    "dxDataGrid-columnFixingUnfix": "Lås upp",
    "dxDataGrid-columnFixingLeftPosition": "Till vänster",
    "dxDataGrid-columnFixingRightPosition": "Till höger",
    "dxDataGrid-exportTo": "Exportera",
    "dxDataGrid-exportToExcel": "Exportera till Excel-fil",
    "dxDataGrid-exporting": "Exporterar...",
    "dxDataGrid-excelFormat": "Excel-fil",
    "dxDataGrid-selectedRows": "Valda rader",
    "dxDataGrid-exportSelectedRows": "Exportera valda rader till {0}",
    "dxDataGrid-exportAll": "Exporta all data till {0}",
    "dxDataGrid-headerFilterLabel": "Filterval",
    "dxDataGrid-headerFilterIndicatorLabel": "Visa filterval för kolumn '{0}'",
    "dxDataGrid-headerFilterEmptyValue": "(Tomma)",
    "dxDataGrid-headerFilterOK": "OK",
    "dxDataGrid-headerFilterCancel": "Avbryt",
    "dxDataGrid-ariaAdaptiveCollapse": "Göm ytterligare data",
    "dxDataGrid-ariaAdaptiveExpand": "Visa ytterligare data",
    "dxDataGrid-ariaColumn": "Kolumn",
    "dxDataGrid-ariaValue": "Värde",
    "dxDataGrid-ariaFilterCell": "Filtrera cell",
    "dxDataGrid-ariaCollapse": "Kollapsa",
    "dxDataGrid-ariaExpand": "Expandera",
    "dxDataGrid-ariaDataGrid": "Datalista",
    "dxDataGrid-ariaSearchInGrid": "Sök i datalista",
    "dxDataGrid-ariaSelectAll": "Välj alla",
    "dxDataGrid-ariaSelectRow": "Välj rad",
    "dxDataGrid-ariaToolbar": "Verktygsrad",
    "dxDataGrid-filterBuilderPopupTitle": "Filterverktyg",
    "dxDataGrid-filterPanelCreateFilter": "Skapa filter",
    "dxDataGrid-filterPanelClearFilter": "Rensa",
    "dxDataGrid-filterPanelFilterEnabledHint": "Aktivera filter",

    "dxPager-infoText": "Sida {0} av {1} ({2} objekt)",
    "dxPager-pagesCountText": "av",
    "dxPager-pageSize": "Rader på sida: {0}",
    "dxPager-pageSizesAllText": "Allt",
    "dxPager-page": "Sida {0}",
    "dxPager-prevPage": "Föregående sida",
    "dxPager-nextPage": "Nästa sida",
    "dxPager-ariaLabel": "Sidnavigering",

    "dxColorView-ariaRed": "Röd",
    "dxColorView-ariaGreen": "Grön",
    "dxColorView-ariaBlue": "Blå",
    "dxColorView-ariaAlpha": "Transparens",
    "dxColorView-ariaHex": "Färgkod",

    "dxTagBox-selected": "{0} valda",
    "dxTagBox-allSelected": "Alla valda ({0})",
    "dxTagBox-moreSelected": "{0} fler",

    "dxFilterBuilder-and": "Och",
    "dxFilterBuilder-or": "Eller",
    "dxFilterBuilder-notAnd": "Inte och",
    "dxFilterBuilder-notOr": "Inte eller",
    "dxFilterBuilder-addCondition": "Lägg till villkor",
    "dxFilterBuilder-addGroup": "Lägg till grupp",
    "dxFilterBuilder-enterValueText": "<ange värde>",
    "dxFilterBuilder-filterOperationEquals": "Lika med",
    "dxFilterBuilder-filterOperationNotEquals": "Inte lika med",
    "dxFilterBuilder-filterOperationLess": "Mindre än",
    "dxFilterBuilder-filterOperationLessOrEquals": "Mindre än eller lika med",
    "dxFilterBuilder-filterOperationGreater": "Större än",
    "dxFilterBuilder-filterOperationGreaterOrEquals": "Större än eller lika med",
    "dxFilterBuilder-filterOperationStartsWith": "Börjar med",
    "dxFilterBuilder-filterOperationContains": "Innehåller",
    "dxFilterBuilder-filterOperationNotContains": "Innehåller inte",
    "dxFilterBuilder-filterOperationEndsWith": "Slutar med",
    "dxFilterBuilder-filterOperationIsBlank": "Är tom",
    "dxFilterBuilder-filterOperationIsNotBlank": "Är inte tom",
    "dxFilterBuilder-filterOperationBetween": "Mellan",
    "dxFilterBuilder-filterOperationAnyOf": "Någon av",
    "dxFilterBuilder-filterOperationNoneOf": "Ingen av",

    Add: "Lägg till",
    AnErrorHasOccurred: "Ett fel har inträffat",
    SideNavMenuHomeLabel: "Fastighetsarkiv",
    SideNavMenuExampleLabel: "Exempel",
    SideNavMenuTasksLabel: "Uppgifter",
    SideNavMenuTasksFiles: "Filer",
    ReusableEntitiesLabel: "Byggnader",
    ReusableProfileLabel: "Profil",
    ReusablePaginationLabel: "Sida {0} av {1} ({2} objekt)",
    ReusableNameLabel: "Namn",
    ReusableComplexLabel: "Fastighet",
    ReusableBackButtonLabel: "Tillbaka",
    ReusableNextButtonLabel: "Nästa",
    ReusableCancelButtonLabel: "Avbryt",
    ReusableUploadButtonLabel: "Ladda upp",
    ReusableComplexInputNamePlaceholder: "Fastighetsnamn",
    ReusableEntityInputNamePlaceholder: "Byggnadsnamn",
    ReusableCreateComplexLabel: "SKAPA FASTIGHET",
    ReusableModelsLabel: "Modeller",
    ReusableComplexIdLabel: "Fastighet ID",
    ReusableEntityIdLabel: "Enhets-ID",
    ReusableModelNameLabel: "Modellnamn",
    ReusableIdLabel: "ID",
    ReusableGuidLabel: "GUID",
    ReusableFmGuidLabel: "FMGUID",
    ReusableLookupLabel: "Värdelista",
    ReusableLookupValuesLabel: "Värdelista",
    ReusableNoDataInListLabel: "Ingen data tillgänglig",
    ReusableRevision: "Revision",
    ReusableDataGrid: "Datalista",
    ReusableDataGridAnd3d: "Datalista & 3D",
    ReusableDraftChangesValidationFail: "Validering misslyckades",
    ReusableUploadFailed: "Uppladdning misslyckades",
    ReusableSaveFailed: "Sparning misslyckades",
    ReusableCancelFailed: "Makulering misslyckades",
    ReusableSavingChanges: "Sparar ändringar...",
    ReusableAddingChanges: "Lägger till ändringar...",
    ReusableCancellingChanges: "Makulerar ändringar...",
    ReusableOKButtonLabel: "OK",
    ReusableCreate: "Skapa",
    ReusableEdit: "Ändra",
    ReusableEntity: "Byggnad",
    ReusableClose: "Stäng",
    ReusablePublish: "Publicera",
    ReusableCancelDraft: "Ångra utkast",
    ReusableExpiredDate: "Utgångsdatum",
    CreateProjectModalAddModelButtonLabel: "Lägg till",
    CreateProjectModalInputNameRequiredText: "Namn krävs",
    CreateProjectModalInputGuidWrongFormatText: "Guiden är inte i giltigt format!",
    FileStatusGenerating: "Genererar",
    DownloadFile: "Ladda ner",
    DownloadIfc: "Ladda ner IFC",
    ShowExpired: "Visa raderade",
    LanguagePanelMenuLabel: "Språk",
    HeaderToolbarLogoutMenuLabel: "Logga ut",
    AppFooterLabel: "Alla varumärken eller registrerade varumärken tillhör sina respektive ägare.",
    AppFooterCopyrighLabel: "Upphovsrätt",
    FileStatus: "Filstatus",
    FileStatusUploaded: "Uppladdat",
    FileStatusLaunchContainer: "Öppnar container",
    FileStatusProcessingObjects: "Bearbetar objekt",
    FileStatusProcessingPropertiesAndGeometries: "Bearbetar egenskaper och geometrier]",
    FileStatusFailed: "Misslyckades",
    FileStatusFailedValidation: "Misslyckad validering",
    FileStatusSucceeded: "Genomförd",
    FileStatusDeleted: "Raderad",
    Size: "Storlek",
    FileID: "Fil ID",
    DateUploaded: "Datum uppladdad",
    FileType: "Bearbetningstyp",
    Progress: "Pågående",
    Log: "Fellogg",
    Validate: "Validera",
    GetFilesError: "Fel från filens metadata",
    ShowActiveFiles: "Aktivt",
    ShowInactiveFiles: "Aktivt",
    DownloadLogsError: "Fel vid nedladdning av logg",
    FileTypeIFC: "IFC",
    FileTypeUnknown: "Okänt",
    SelectFilter: "Välj filter",
    FilterNamePrompt: "Ange nytt filternamn",
    FilterNamePlaceholder: "Ange filternamn",
    AvailableCategories: "Tillgängliga kategorier",
    SelectedCategories: "Valda kategorier",
    Proceed: "Fortsätt",
    ExternalParameters: "Externa Parametrar",
    ExternalParameterSets: "Externa Parametergrupper",
    IsLookup: "Värdelista",
    AssetParameters: "Asset Parametrar",
    Actions: "Åtgärder",
    Mappings: "Mappning",
    Required: "Obligatoriskt",
    Unique: "Unikt",
    Type: "Typ",
    Save: "Spara",
    RemoveMappings: "Ta bort alla",
    ConfirmRemovingAll: "Tar bort alla. Är du säker?",
    Yes: "Ja",
    No: "Nej",
    Mapped: "Mappat",
    Unmapped: "Saknar mappning",
    BimObjectName: "Bimobject name",
    BimObjectExternalId: "Bimobject external ID",
    DataSummary:"Datasammanfattning",
    New: "Ny",
    Changed:"Ändrats",
    Unchanged:"Oförändrat",
    Revived:"Återuppväckt",
    Deleted:"Raderade",
    TotalNew: "Total IFC",
    TotalOld: "Total DB",
    ValidationResults: "Valideringsresultat",
    ResultText: "Resultat, text",
    FailsPerRuleset: "Fel per regel",
    RulesetName: "Regel, namn",
    FailingValue: "Felaktigt värde",
    Finalise: "Fullborda",
    FailedObjects: "Felaktiga objekts",
    ConfirmDeletingImport: "Raderar import. Är du säker?",
    RulesetFail: "Fel i regelverk",
    TotalObjectCount: "Objekt med en felaktig egenskap",
    TotalPropertyCount: "Egenskaper validerade",
    PropertyPass: "Passerade",
    PropertyFail: "Misslyckades",
    FinaliseSucceeded: "Fullbordat genomförande!",
    FinaliseError: "Avbrutet på grund av fel.",
    Delete: "Radera",
    Refresh: "Uppdatera",
    SideNavMenuTasksSettings: "Inställningar",
    Parameters: "Parametrar",
    Administrator: "Administratör",
    Parameter: "Parameter",
    CreateParameter: "Skapa parameter",
    EditParameter: "Redigera parameter",
    ListParameters: "Parameterlista",
    ParameterGroups: "Parametergrupper",
    Color: "Färg",
    CreateParameterGroup: "Skapa parametergrupp",
    EditParameterGroup: "Redigera parametergrupp",
    CreateEditParameterGroupNameRequiredMessage: "Namn måste anges",
    CreateEditParameterGroupNameLengthMessage: "Namnet kan innehålla max {0} tecken",
    AvailableParameters: "Tillgängliga parametrar",
    ListUsers: "Användare",
    ListPermissions: "Rättigheter",
    ImportExcel: "Import från Excel",
    ExportExcel: "Export till Excel",
    ExcelTemplate: "Excel-mall",
    DataType: "Datatyp",
    IsInValues: "Värdelista",
    ObjectType: "Objektstyp",
    IfcTypeValue: "IFC typvärde",
    Error: "Fel",
    CreateParameterValidationFailed: "Validering av parameter misslyckades",
    Success: "Klart!",
    IsFmGuid: "Är FMGuid",
    Rules: "Regler",
    RevisionId: "Revision Id",
    BimObjectId: "BimObject Id",
    Designation: "Beteckning",
    CommonName: "Vanligt namn",
    ExternalId: "Externt Id",
    ComplexFMGuid: "Fastighet Id",
    ComplexDesignation: "Fastighetsbeteckning",
    ComplexCommonName: "Fastighetsnamn",
    EntityFMGuid: "Byggnad Id",
    EntityDesignation: "Byggnadsbeteckning",
    EntityCommonName: "Byggnadsnamn",
    ModelId: "Modell Id",
    ModelDisciplineID: "Modell-Diciplin Id",
    ModelName: "Modellnamn",
    LevelDesignation: "Våningsbeteckning",
    LevelCommonName: "Våningsnamn",
    LevelFmGuid:"Våning FmGuid",
    LevelBimObjectId:"Våning BimObjectId",
    InRoomDesignation: "I rumsnummer",
    InRoomCommonName: "I rumsnamn",
    InRoomBimObjectId: "I rum BimObjectId",
    InRoomFmGuid:"I rum FmGuid",
    LevelName:"Våningsnamn",
    LevelNumber:"Våningsnummer",
    RoomName:"Rumsnamn",
    RoomNumber:"Rumsnummer",
    FromRoomDesignation: "Från rumsnummer",
    FromRoomCommonName: "Från rumsnamn",
    ToRoomDesignation: "Till rumsnummer",
    ToRoomCommonName: "Till rumsnamn",
    PropertyName: "Egenskapsnamn",
    SourceNames: "Ursprungsnamn",
    SourceNamesConfirm: "Ursprungsnamn (Bekräfta nytt värde genom att trycka Enter)",
    SelectFile: "Välj fil",
    ViewAll: "Visa alla",
    HideFiltered: "Dölj filtrerat",
    XRayFiltered: "Filtrerat transparent",
    Show: "Visa",
    Hide: "Dölj",
    ViewFit: "Anpassad vy",
    Object: "Objekt",
    Others: "Andra",
    All: "Alla",
    SelectedItems: "Valda objekt",
    NotSelectedItems: "Ej valda objekt",
    XRay: "Transparent",
    None: "Inga",
    ResetSlices: "Återställ sektioner",
    ViewInSpace: "Visa i rum",
    RevertRowSingle: "Återställ rad (1)",
    RevertRowMultiple: "Återställ rader ({0})",
    RevertCell: "Återställ cell",
    DeleteCellValue: "Ta bort cellvärde",
    SelectNone: "Välj inget",
    SelectParent: "Välj förälder",
    SelectedRowSingle: "Du har en rad markerad",
    SelectedRowMultiple: "Du har %SELNO% rader markerade",
    DifferentValues: "Olika värden",
    EditRowSingle: "Redigera rad (1)",
    EditRowMultiple: "Redigera ({0}) rader",
    ExpireRowSingle: "Radera rad (1)",
    ExpireRowMultiple: "Radera ({0}) rader",
    FormEditRowSingle: "Redigerar (1)",
    FormEditRowMultiple: "Redigerar rader",
    BulkUpdatingData: "Uppdaterar bulkdata",
    OnlyContainNumbers: "Endast nummer",
    ObjectDetails: "Objektegenskaper",
    DateCreated: "Datum skapad",
    DateModified: "Datum ändrad",
    DateExpired: "Utgångsdatum",
    CreatedInModel: "Skapad i modell",
    ComplexValidationFailed: "Valideringsfel för objekt:",
    ComplexCreateFailed: "Fel vid skapande av objekt:",
    FileAlreadyExists: "Filen finns redan",
    FileUploadFailedNoFile: "Ingen fil laddades upp för modell:",
    InvalidFile: "Invalid fil",
    ColumnPicker: "Kolumnväljare",
    MissingColumnNamesInTheFirstRow: "Kolumnnamn saknas på första raden: {0}.",
    ImportParametersListValidationErrorSingularForm: "Det finns {0} fel i kolumn {1}",
    ImportParametersListValidationErrorPluralForm: "Det finns {0} fel i kolumn {1}",
    WarningAboutMissingFile: "Ladda upp IFC för samtliga modeller eller välj Spara för att fortsätta utan uppladdning",
    WarningComplexWasCreated: "Fastighet: {0} har skapats",
    WarningEntityWasCreated: "Byggnad: {0} har skapats",
    WarningEntitiesWereCreated: "Byggnader: {0} har skapats",
    WarningModelWasCreated: "Modell: {0} har skapats",
    WarningAfterCancellingDraft: "Ändringar i metadata sparas. T ex namn på Fastighet, Byggnad eller Modell.",
    GridUsersCreationDate: "Datum inbjudan",
    GridUsersEmailVerified: "Accepterat",
    GridUsersActive: "Aktiv",
    GridUsersRole: "Roll",
    GridUsersEditUser: "Redigera användare",
    GridUsersInviteUser: "Bjud in användare",
    GridUsersEmail: "Email",
    GridUsersFirstName: "Förnamn",
    GridUsersLastName: "Efternamn",
    GridUsersReinvite: "Uppdatera inbjudan",
    GridPermissionsName: "Rättigheter",
    GridPermissionsViewer: "Betraktare",
    GridPermissionsEditor: "Redigerare",
    GridPermissionsAdmin: "Administratör",
    AuthenticationFailed: "Autentiering misslyckades",
    UserIsNotAcceptedYet: "Kontot har ej accepterats",
    NewLabel: "Ny",
    DraftLabel: "Utkast",
    ShowArchiveObjects: "Arkiv",
    ObjectsNotOriginatedInIFC: "Objekt som inte fanns ursprungligen i IFC exporteras ej.",
    NoDataToRevertRows: "Markerade rader har inga data att återställa.",
    NoDataToRevertRow:"Den valda raden har inga data att återställa.",
    PublishingData:"Data publiceras. Uppdatera (F5) för att kontrollera om det är klart.",
    NoData:"Inga data",
    ClearView: "ÅTERSTÄLL",
    ViewListMenu: "Visa listmenyn",
    DataGridSelectedViewListPlaceholder: "Välj en vy",
    CreateView: "Skapa vy",
    DeleteView: "Ta bort vy",
    UpdateView: "UPPDATERA VY",
    DeleteViewPrompt: "Vyn är aktiv. Ta bort?",
    ReusableOK: "OK",
    ViewListNamePlaceholder: "Fyll i vyns namn",
    ViewListNameRequiredRule: "Namn krävs",
    ViewCreatedSuccessfully: "Vyn har skapats",
    ViewUpdatedSuccessfully: "Vyn har uppdaterats",
    ViewListNamePatternRule: "Endast alfanumeriska och följande tecken tillåts: [ ] _  -  +  !  @  ( ) : , / \\ samt mellanrum",
    IntegerValidationMessage:"Endast heltalsvärden är tillåtna",
    IFCFileNameValidationRuleForCloud:"Endast dessa specialtecken är tillåtna i IFC-filnamn +.#_-,;()'%~$@",
    ParameterAlreadyExists:"Parametern {0} finns redan.",
    PartialSuccess:"En eller flera parametrar har misslyckats med valideringen."
  },
};

export default {
  getLocales() {
    return locales;
  },
  getDictionary() {
    return dictionary;
  }
};
