
import { bimAPI } from "@/utils/api";
import auth from "../authsrc/auth";
import { formatMessage } from "devextreme/localization";
import { DxButton } from "devextreme-vue/button";
import {
  DxButton as DxTreeButton,
  DxColumn,
  DxItem,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxSelection,
  DxToolbar,
  DxTreeList,
  DxValidationRule
} from "devextreme-vue/tree-list";
import DataSource from "devextreme/data/data_source";
import {
  GetBimObjectsByTypeResponse,
  GetBimObjectsByTypeResponseBuildingRevisionStatusEnum,
  GetBimObjectsByTypeResponseComplexRevisionStatusEnum,
  GetBimObjectsByTypeResponseModelRevisionStatusEnum,
  GetBimObjectsByTypeResponseModelTypeEnum,
  BimObjectObjectTypeEnum
} from "@swg/api";
import {
  CellPreparedEvent,
  ColumnButtonClickEvent,
  ColumnCellTemplateData,
  EditorPreparingEvent,
  SelectionChangedEvent,
  InitializedEvent
} from "devextreme/ui/tree_list";
import { DxSwitch } from "devextreme-vue/switch";
import store from "../store";
import notify from "devextreme/ui/notify";
import { defineComponent } from "vue";

interface ComplexObject {
  Parent: string;
  Name: string;
  FmGuid: string;
  ExternalGuid: string;
  ObjectType: number;
  RevisionId: string;
  ComplexId: string;
  ComplexName: string;
  ComplexDesignation: string;
  ExpiredDate?: Date,
  BimObjectId: string;
  RevisionStatus: GetBimObjectsByTypeResponseComplexRevisionStatusEnum;
}

interface BuildingObject {
  Parent: string;
  ParentName: string;
  Name: string;
  FmGuid: string;
  ExternalGuid: string;
  ObjectType: number;
  RevisionId: string;
  ComplexId: string;
  BuildingMetaDataId: string;
  BuildingMetaDataName: string;
  BuildingDesignation: string;
  ExpiredDate?: Date,
  BimObjectId: string;
  RevisionStatus: GetBimObjectsByTypeResponseBuildingRevisionStatusEnum;
}

interface ModelObject {
  Parent: string;
  ParentName: string;
  Name: string;
  ExternalGuid?: string;
  ObjectType: number;
  RevisionId: string;
  ModelMetaDataId: string;
  ModelMetaDataName: string;
  ExpiredDate?: Date,
  BimObjectId: string;
  ComplexId: string;
  RevisionStatus: GetBimObjectsByTypeResponseModelRevisionStatusEnum;
  ModelType: GetBimObjectsByTypeResponseModelTypeEnum;
}

type BimObject = ComplexObject | BuildingObject | ModelObject;

const position = "bottom center";
const direction = "up-push";
const displayTime = 5000;

interface ColumnMetadata
{
  dataField: string;
  visible: boolean;
  caption: string,
  required?: boolean,
}

export default defineComponent({
  name: "ComplexList",
  components: {
    DxTreeList,
    DxSelection,
    DxColumn,
    DxPaging,
    DxPager,
    DxToolbar,
    DxItem,
    DxButton,
    DxScrolling,
    DxValidationRule,
    DxSearchPanel,
    DxTreeButton,
    DxSwitch
  },
  emits: ["createProject", "goToDataGrid", "goToViewer"],
  data() {
    return {
      dataSource: new DataSource({
        key: "BimObjectId",
        load: async () => {
          const bimObjectsByType = await bimAPI.getBimObjectsByType();
          return this.$_mapData(bimObjectsByType);
        }
      }),
      columns: [
        {
          dataField: "Designation",
          visible: false,
          caption: formatMessage("BimObjectExternalId"),
        },
        {
          dataField: "ExternalGuid",
          visible: false,
          caption: formatMessage("ReusableFmGuidLabel"),
          required: true,
        },
        {
          dataField: "BimObjectType",
          visible: false,
          caption: formatMessage("ObjectType"),
        },
        {
          dataField: "RevisionId",
          visible: false,
          caption: formatMessage("ReusableRevision"),
        },
      ] as ColumnMetadata[],
      columnName: {
        name: formatMessage("ReusableNameLabel"),
      },
      labels: {
        createComplexLabel: formatMessage("ReusableCreateComplexLabel"),
        dataGridLabel: formatMessage("ReusableDataGrid"),
        dataGridAnd3dLabel: formatMessage("ReusableDataGridAnd3d"),
        reusablePaginationLabel: formatMessage("ReusablePaginationLabel"),
        switchLabel: formatMessage("ShowArchiveObjects"),
        publishLabel: formatMessage("ReusablePublish"),
        cancelDraftLabel: formatMessage("ReusableCancelDraft"),
        expiredDate: formatMessage("ReusableExpiredDate")
      },
      disabledColumns: ["BimObjectType", "RevisionId"],
      selectedModels: [] as BimObject[],
      selectedComplexes: [] as BimObject[],
      publishingDisabled: true,
      permissions: auth.getPermissions(),
      treeComponent: null,
      archiveVisible: false
    };
  },
  watch: {
    archiveVisible() {
      this.dataSource.reload();
    }
  },
  methods: {
    applyBellIcon(data: ColumnCellTemplateData<BimObject, string>): string {
      const newText = ` (🔔 ${formatMessage("NewLabel")})`;
      const draftText = ` (🔔 ${formatMessage("DraftLabel")})`;

      if ((data.row.data as ComplexObject).ComplexId) {
        const complexObj = data.row.data as ComplexObject;
        switch (complexObj.RevisionStatus) {
          case GetBimObjectsByTypeResponseComplexRevisionStatusEnum.New:
            return newText;
          case GetBimObjectsByTypeResponseComplexRevisionStatusEnum.Draft:
            return draftText;
          case GetBimObjectsByTypeResponseComplexRevisionStatusEnum.Cancelled:
          case GetBimObjectsByTypeResponseComplexRevisionStatusEnum.Published:
            return "";
        }
      } else if ((data.row.data as BuildingObject).BuildingMetaDataId) {
        const buildingObj = data.row.data as BuildingObject;
        switch (buildingObj.RevisionStatus) {
          case GetBimObjectsByTypeResponseBuildingRevisionStatusEnum.New:
            return newText;
          case GetBimObjectsByTypeResponseBuildingRevisionStatusEnum.Draft:
            return draftText;
          case GetBimObjectsByTypeResponseBuildingRevisionStatusEnum.Cancelled:
          case GetBimObjectsByTypeResponseBuildingRevisionStatusEnum.Published:
            return "";
        }
      } else {
        const modelObj = data.row.data as ModelObject;
        switch (modelObj.RevisionStatus) {
          case GetBimObjectsByTypeResponseModelRevisionStatusEnum.New:
            return newText;
          case GetBimObjectsByTypeResponseModelRevisionStatusEnum.Draft:
            return draftText;
          case GetBimObjectsByTypeResponseModelRevisionStatusEnum.Cancelled:
          case GetBimObjectsByTypeResponseModelRevisionStatusEnum.Published:
            return "";
        }
      }
      return "";
    },
    onEditorPreparing(event: EditorPreparingEvent<BimObject, string>) {
      const isDisabled = this.disabledColumns.some(
        (item) => item == event.dataField
      );
      if (isDisabled) {
        event.editorOptions.disabled = true;
      }
    },
    getSelectedModels(bimObjects: BimObject[]): BimObject[] {
      bimObjects = bimObjects.filter((bimObject) => bimObject.ObjectType === BimObjectObjectTypeEnum.Model);
      if (bimObjects.length === 0) {
        return [];
      }
      return bimObjects;
    },
    onCellPrepared(event: CellPreparedEvent<BimObject, string>) {
      if (!event.data) {
        return;
      }
      if (event.column.name === "buttons") {
        if ((event.data as ModelObject).ModelMetaDataId) {
          this.$_hideEditButtonIfModelIsOfTypeOrphan(event.data as ModelObject, event);
        }
      }
    },
    onInitialized(event: InitializedEvent<BimObject, string>){
      this.treeComponent = event.component;
    },
    $_hideEditButtonIfModelIsOfTypeOrphan(model: ModelObject, event: CellPreparedEvent<BimObject, string>) {
      if (model.ModelType === GetBimObjectsByTypeResponseModelTypeEnum.Orphan) {
        const editButton = event.cellElement.querySelector("a.dx-icon-edit");
        editButton?.remove();
      }
    },
    asModelIds(list) {
      return list.map((item) => item.BimObjectId);
    },
    onSelectionChanged(event: SelectionChangedEvent<BimObject, string>) {
      const selectedLeaves = event.component.getSelectedRowsData("leavesOnly");
      this.selectedModels = this.getSelectedModels(selectedLeaves);
      this.selectedComplexes = event.component.getSelectedRowsData("all").filter(x => x.Parent === null);

      this.checkPublishingDisabled();
    },
    checkPublishingDisabled(){
      if (this.selectedModels.length) {
        this.publishingDisabled = false;
      } else{
        this.publishingDisabled = true;
      }

      if (this.selectedModels.every(x => x.RevisionStatus !== GetBimObjectsByTypeResponseModelRevisionStatusEnum.Draft)){
        this.publishingDisabled = true;
      }

      this.selectedModels.forEach(m => {
        if (!this.selectedComplexes.map(x => x.ComplexId).includes(m.ComplexId)) {
          this.publishingDisabled = true;
        }
      })
    },
    async reloadData() {
      await this.dataSource.reload();
      const selectedLeaves = this.treeComponent.getSelectedRowsData("leavesOnly");
      this.selectedModels = this.getSelectedModels(selectedLeaves);
      this.checkPublishingDisabled();
    },
    editObject(event: ColumnButtonClickEvent<BimObject, string>) {
      const complexId = event.row.data.ComplexId;
      const objectType = event.row.data.ObjectType;
      const rawComplex = this.dataSource.items().find(x => x.data.ComplexId === complexId);
      const complex = {
        name: rawComplex.data.Name,
        bimObjectId: rawComplex.data.BimObjectId,
        id: rawComplex.data.ComplexDesignation,
        fmGuid: rawComplex.data.FmGuid,
        externalGuid: rawComplex.data.ExternalGuid,
        revisionStatus: rawComplex.data.RevisionStatus,
        revisionId: rawComplex.data.RevisionId,
        created: true,
        expiredDate: rawComplex.data.ExpiredDate,
        entities: rawComplex.children.map(x => ({
          name: x.data.Name,
          bimObjectId: x.data.BimObjectId,
          id: x.data.BuildingDesignation,
          fmGuid: x.data.FmGuid,
          externalGuid: x.data.ExternalGuid,
          revisionStatus: x.data.RevisionStatus,
          created: true,
          expiredDate: x.data.ExpiredDate,
          models: x.children.filter(c => c.data.ModelType !== GetBimObjectsByTypeResponseModelTypeEnum.Orphan).map(y => ({
            name: y.data.Name,
            bimObjectId: y.data.BimObjectId,
            revisionStatus: y.data.RevisionStatus,
            created: true,
            expiredDate: y.data.ExpiredDate,
          }))
        }))
      };

      this.$emit("createProject", complex, objectType, true);
    },
    async publish() {
      await store.dispatch("setLoader", true);
      console.log(this.selectedModels.map(s => s.BimObjectId));
      const modelIdsToSave = this.selectedModels.map(s => s.BimObjectId);

      await bimAPI.saveDraftChanges({
        bIMClassesModelsAPIPayloadDraftChanges: {
          complete: false,
          modelIds: modelIdsToSave,
          data: []
        }
      }).catch((error) => {
        console.log(error);
        this.waitingForResponse = false;
        notify({ message: "Error publish for models", type: "error", displayTime }, {
          position,
          direction
        });
        return error.response.status;
      }).finally(() =>{
        store.dispatch("setLoader", false);
        this.reloadData();
      });
    },
    async cancelDraft() {
      await store.dispatch("setLoader", true);
      const modelIds = this.selectedModels.map(x => x.BimObjectId);
      await bimAPI.cancelDraftChanges({
        bIMClassesModelsAPIPayloadPayloadWithModelIds: {
          modelIds: modelIds
        }
      })
        .catch((error) => {
          console.log(error);
          this.waitingForResponse = false;
          notify({ message: "Error canceling draft for models: " + modelIds, type: "error", displayTime }, {
            position,
            direction
          });
          return error.response.status;
        }).finally(() =>{
          store.dispatch("setLoader", false);
          this.reloadData();
          notify({ message: formatMessage("WarningAfterCancellingDraft"), type: "warning", displayTime }, {
            position,
            direction
          });
        });
    },
    $_mapData(bimObjectsByType: GetBimObjectsByTypeResponse[]): BimObject[] {
      if (!bimObjectsByType) {
        return null;
      }

      const complexes: ComplexObject[] = [];
      const buildings: BuildingObject[] = [];
      const models: ModelObject[] = [];

      bimObjectsByType.forEach((bimObject) => {
        const complexExists = bimObject.complexBimObjectId !== undefined && !complexes.find(
          (element) => element.BimObjectId == bimObject.complexBimObjectId
        );
        const buildingExists = bimObject.buildingBimObjectId !== undefined && !buildings.find(
          (element) => element.BimObjectId == bimObject.buildingBimObjectId
        );
        const modelExists = bimObject.modelBimObjectId !== undefined && !models.find(
          (element) => element.BimObjectId == bimObject.modelBimObjectId
        );

        if (complexExists) {
          complexes.push({
            Parent: null,
            Name: bimObject.complexName,
            FmGuid: bimObject.complexFmGuid,
            ExternalGuid: bimObject.complexExternalGuid,
            ObjectType: BimObjectObjectTypeEnum.Complex,
            RevisionId: bimObject.revisionId,
            ComplexId: bimObject.complexId,
            ComplexName: bimObject.complexName,
            ComplexDesignation: bimObject.complexDesignation,
            ExpiredDate: bimObject.complexExpiredDate,
            BimObjectId: bimObject.complexBimObjectId,
            RevisionStatus: bimObject.complexRevisionStatus
          });
        }

        if (buildingExists) {
          buildings.push({
            Parent: bimObject.complexBimObjectId,
            ParentName: bimObject.complexName,
            Name: bimObject.buildingMetaDataName,
            FmGuid: bimObject.buildingFmGuid,
            ExternalGuid: bimObject.buildingExternalGuid,
            ObjectType: BimObjectObjectTypeEnum.Building,
            RevisionId: bimObject.revisionId,
            ComplexId: bimObject.complexId,
            BuildingMetaDataId: bimObject.buildingMetaDataId,
            BuildingMetaDataName: bimObject.buildingMetaDataName,
            BuildingDesignation: bimObject.buildingDesignation,
            ExpiredDate: bimObject.buildingExpiredDate,
            BimObjectId: bimObject.buildingBimObjectId,
            RevisionStatus: bimObject.buildingRevisionStatus
          });
        }

        if (modelExists) {
          models.push({
            Parent: bimObject.buildingBimObjectId,
            ParentName: bimObject.buildingMetaDataName,
            Name: bimObject.modelMetaDataName,
            ExternalGuid: bimObject.modelExternalGuid,
            ObjectType: BimObjectObjectTypeEnum.Model,
            RevisionId: bimObject.revisionId,
            ModelMetaDataId: bimObject.modelMetaDataId,
            ModelMetaDataName: bimObject.modelMetaDataName,
            ExpiredDate: bimObject.modelExpiredDate,
            BimObjectId: bimObject.modelBimObjectId,
            ComplexId: bimObject.complexId,
            RevisionStatus: bimObject.modelRevisionStatus,
            ModelType: bimObject.modelType
          });
        }
      });

      if (this.archiveVisible) {
        return this.$_removeNonExpiredObjectsAndItsChildren(complexes, buildings, models);
      } else {
        return this.$_removeExpiredObjectsAndItsChildren(complexes, buildings, models);
      }
    },
    $_removeModelsWithNoExpiredDate: function (modelsRelatedToBuilding: ModelObject[], expiredModels: ModelObject[]) {
      for (const model of modelsRelatedToBuilding) {
        if (!model.ExpiredDate) {
          const index = expiredModels.findIndex(em => em.BimObjectId === model.BimObjectId);
          expiredModels.splice(index, 1);
        }
      }
    },
    $_removeBuildingThatHasNoExpiredDate: function (buildingsRelatedToComplex: BuildingObject[], models: ModelObject[], expiredModels: ModelObject[], buildingModels: { [p: string]: boolean }, expiredBuildings: BuildingObject[]) {
      for (const building of buildingsRelatedToComplex) {
        if (building.ExpiredDate) {
          continue;
        }

        const modelsRelatedToBuilding = models.filter(m => m.Parent === building.BimObjectId);
        this.$_removeModelsWithNoExpiredDate(modelsRelatedToBuilding, expiredModels);

        const allModelsHasNoExpiredDate = modelsRelatedToBuilding.every(m => !m.ExpiredDate);
        buildingModels[building.BimObjectId] = allModelsHasNoExpiredDate;
        if (allModelsHasNoExpiredDate) {
          const index = expiredBuildings.findIndex(em => em.BimObjectId === building.BimObjectId);
          expiredBuildings.splice(index, 1);
        }
      }
    },
    $_removeNonExpiredObjectsAndItsChildren(complexes: ComplexObject[], buildings: BuildingObject[], models: ModelObject[]): BimObject[] {
      let expiredComplexes = [...complexes];
      let expiredBuildings = [...buildings];
      let expiredModels = [...models];
      const buildingModels: { [bimObjectId: string]: boolean } = {};

      for (const complex of complexes) {
        if (complex.RevisionStatus !== GetBimObjectsByTypeResponseComplexRevisionStatusEnum.Published) {
          const buildingsRelatedToComplex = buildings.filter(b => b.Parent === complex.BimObjectId);
          this.$_removeBuildingThatHasNoExpiredDate(buildingsRelatedToComplex, models, expiredModels, buildingModels, expiredBuildings);
          const index = expiredComplexes.findIndex(em => em.BimObjectId === complex.BimObjectId);
          expiredComplexes.splice(index, 1);
          continue;
        }

        if (complex.ExpiredDate) {
          continue;
        }

        const buildingsRelatedToComplex = buildings.filter(b => b.Parent === complex.BimObjectId);
        this.$_removeBuildingThatHasNoExpiredDate(buildingsRelatedToComplex, models, expiredModels, buildingModels, expiredBuildings);

        const allChildrenHasNoExpiredDate = buildingsRelatedToComplex.every(b => !b.ExpiredDate && buildingModels[b.BimObjectId]);
        if (allChildrenHasNoExpiredDate) {
          const index = expiredComplexes.findIndex(em => em.BimObjectId === complex.BimObjectId);
          expiredComplexes.splice(index, 1);
        }
      }

      return [...expiredComplexes, ...expiredBuildings, ...expiredModels];
    },
    $_removeExpiredObjectsAndItsChildren(complexes: ComplexObject[], buildings: BuildingObject[], models: ModelObject[]): BimObject[] {
      const expiredComplexes = complexes.filter(x => x.RevisionStatus === GetBimObjectsByTypeResponseComplexRevisionStatusEnum.Published && x.ExpiredDate);
      const expiredBuildings = buildings.filter(x => x.RevisionStatus === GetBimObjectsByTypeResponseBuildingRevisionStatusEnum.Published && x.ExpiredDate);
      const expiredModels = models.filter(x => x.RevisionStatus === GetBimObjectsByTypeResponseModelRevisionStatusEnum.Published && x.ExpiredDate);

      const nonExpiredComplexes = complexes.filter(x => !expiredComplexes.some(y => y.BimObjectId === x.BimObjectId));
      const nonExpiredBuildings = buildings.filter(x => !expiredBuildings.some(y => y.BimObjectId === x.BimObjectId));
      const nonExpiredModels = models.filter(x => !expiredModels.some(y => y.BimObjectId === x.BimObjectId));

      return [...nonExpiredComplexes, ...nonExpiredBuildings, ...nonExpiredModels];
    }
  },
});
