import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "switch-label" }
const _hoisted_2 = { class: "top-bottom-margin dx-card dx-grid" }
const _hoisted_3 = { class: "dx-treelist-text-content" }
const _hoisted_4 = { class: "italic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSwitch = _resolveComponent("DxSwitch")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxPaging = _resolveComponent("DxPaging")!
  const _component_DxPager = _resolveComponent("DxPager")!
  const _component_DxSelection = _resolveComponent("DxSelection")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxValidationRule = _resolveComponent("DxValidationRule")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxTreeButton = _resolveComponent("DxTreeButton")!
  const _component_DxItem = _resolveComponent("DxItem")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxToolbar = _resolveComponent("DxToolbar")!
  const _component_DxTreeList = _resolveComponent("DxTreeList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.labels.switchLabel), 1),
    _createVNode(_component_DxSwitch, {
      value: _ctx.archiveVisible,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.archiveVisible) = $event))
    }, null, 8, ["value"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DxTreeList, {
        id: "BimObject",
        "data-source": _ctx.dataSource,
        "show-borders": true,
        "auto-expand-all": false,
        "focused-row-enabled": true,
        "filter-mode": "fullBranch",
        "parent-id-expr": "Parent",
        "key-expr": "BimObjectId",
        onEditorPreparing: _ctx.onEditorPreparing,
        onSelectionChanged: _ctx.onSelectionChanged,
        onCellPrepared: _ctx.onCellPrepared,
        onInitialized: _ctx.onInitialized
      }, {
        projectTemplate: _withCtx(() => [
          _createVNode(_component_DxButton, {
            icon: "smalliconslayout",
            type: "success",
            text: _ctx.labels.createComplexLabel,
            hint: _ctx.labels.createComplexLabel,
            disabled: !_ctx.permissions.complex.canCreate,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('createProject', 0, 0, false)))
          }, null, 8, ["text", "hint", "disabled"])
        ]),
        dataGridTemplate: _withCtx(() => [
          _createVNode(_component_DxButton, {
            icon: "rowproperties",
            type: "success",
            disabled: !_ctx.permissions.dataGrid.canView || _ctx.selectedModels.length === 0,
            text: _ctx.labels.dataGridLabel,
            hint: _ctx.labels.dataGridLabel,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('goToDataGrid', _ctx.asModelIds(_ctx.selectedModels), false, _ctx.archiveVisible)))
          }, null, 8, ["disabled", "text", "hint"])
        ]),
        dataGridAndViewTemplate: _withCtx(() => [
          _createVNode(_component_DxButton, {
            icon: "rowproperties",
            type: "success",
            disabled: !_ctx.permissions.dataGrid.canView || !_ctx.permissions.threeDimensional.canView || _ctx.selectedModels.length === 0,
            text: _ctx.labels.dataGridAnd3dLabel,
            hint: _ctx.labels.dataGridAnd3dLabel,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('goToDataGrid', _ctx.asModelIds(_ctx.selectedModels), true)))
          }, null, 8, ["disabled", "text", "hint"])
        ]),
        customCellTemplate: _withCtx(({ data }) => [
          _createElementVNode("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(data.text), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.applyBellIcon(data)), 1)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_DxScrolling, { mode: "standard" }),
          _createVNode(_component_DxPaging, {
            enabled: true,
            "page-size": 50
          }),
          _createVNode(_component_DxPager, {
            "allowed-page-sizes": [25, 50, 100, 200],
            "display-mode": "full",
            "show-page-size-selector": true,
            "show-info": true,
            "show-navigation-buttons": true,
            visible: true,
            "info-text": _ctx.labels.reusablePaginationLabel
          }, null, 8, ["info-text"]),
          _createVNode(_component_DxSelection, {
            recursive: true,
            "allow-select-all": false,
            mode: "multiple"
          }),
          _createVNode(_component_DxSearchPanel, {
            width: 300,
            visible: true
          }),
          _createVNode(_component_DxColumn, {
            "data-field": "Name",
            disabled: "true",
            "allow-editing": false,
            caption: _ctx.columnName.name,
            "sort-order": "asc",
            "cell-template": "customCellTemplate"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxValidationRule, { type: "required" })
            ]),
            _: 1
          }, 8, ["caption"]),
          _createVNode(_component_DxColumn, {
            "data-field": "ExpiredDate",
            visible: _ctx.archiveVisible,
            caption: _ctx.labels.expiredDate
          }, null, 8, ["visible", "caption"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
            return (_openBlock(), _createBlock(_component_DxColumn, {
              key: column.dataField,
              "data-field": column.dataField,
              visible: column.visible,
              caption: column.caption
            }, {
              default: _withCtx(() => [
                (column.required)
                  ? (_openBlock(), _createBlock(_component_DxValidationRule, {
                      key: 0,
                      type: "required"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["data-field", "visible", "caption"]))
          }), 128)),
          _createVNode(_component_DxColumn, {
            type: "buttons",
            alignment: "right"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTreeButton, {
                visible: !_ctx.archiveVisible,
                icon: "edit",
                onClick: _ctx.editObject
              }, null, 8, ["visible", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_DxToolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_DxItem, {
                location: "before",
                template: "projectTemplate"
              }),
              _createVNode(_component_DxItem, {
                location: "after",
                template: "dataGridTemplate"
              }),
              _createVNode(_component_DxItem, {
                location: "after",
                template: "dataGridAndViewTemplate"
              }),
              _createVNode(_component_DxItem, null, {
                default: _withCtx(() => [
                  _createVNode(_component_DxButton, {
                    icon: "save",
                    type: "success",
                    disabled: !_ctx.permissions.dataGrid.canUpdate || _ctx.publishingDisabled,
                    hint: _ctx.labels.publishLabel,
                    onClick: _ctx.publish
                  }, null, 8, ["disabled", "hint", "onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_DxItem, null, {
                default: _withCtx(() => [
                  _createVNode(_component_DxButton, {
                    icon: "revert",
                    type: "success",
                    disabled: !_ctx.permissions.dataGrid.canUpdate || _ctx.publishingDisabled,
                    hint: _ctx.labels.cancelDraftLabel,
                    onClick: _ctx.cancelDraft
                  }, null, 8, ["disabled", "hint", "onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_DxItem, { name: "searchPanel" })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data-source", "onEditorPreparing", "onSelectionChanged", "onCellPrepared", "onInitialized"])
    ])
  ], 64))
}