<template>
  <div
    id="mappingName"
    class="form"
  >
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          {{ getTranslate('ReusableNameLabel') }}
        </div>
        <div class="dx-field-value">
          <DxAutocomplete
            v-model:value="mappingObject.name"
            :data-source="assetParametersNames"
            :placeholder="getTranslate('ReusableNameLabel')"
            @value-changed="valueChanged"
          />
        </div>
      </div>
    </div>
  </div>
  <DxForm
    id="mappingForm"
    ref="complexForm"
    :form-data="mappingObject"
    :align-item-labels="false"
    :align-item-labels-in-all-groups="false"
  >
    <DxGroupItem
      :col-count="5"
      :caption="getTranslate('Rules')"
    >
      <DxSimpleItem
        v-model="selectedType"
        :editor-options="{value: '', searchEnabled: false, items: dataTypes, value: mappingObject.type, disabled: isSet(mappingObject)}"
        data-field="type"
        editor-type="dxSelectBox"
      >
        <DxLabel
          label-mode="'outside'"
          :text="getTranslate('Type')"
        />
      </DxSimpleItem>
      <DxSimpleItem
        :editor-options="checkBoxOptions"
        data-field="required"
        editor-type="dxCheckBox"
      >
        <DxLabel :text="getTranslate('Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        data-field="unique"
        editor-type="dxCheckBox"
        :editor-options="{ disabled: isSet(mappingObject) }"
      >
        <DxLabel :text="getTranslate('Unique')" />
      </DxSimpleItem>
      <DxSimpleItem
        data-field="isFmGuid"
        editor-type="dxCheckBox"
        :editor-options="{ disabled: isSet(mappingObject) || isFMGuidDisabled(parameter.parameterId) }"
      >
        <DxLabel :text="getTranslate('ReusableFmGuidLabel')" />
      </DxSimpleItem>
      <DxSimpleItem
        :editor-options="checkBoxOptions"
        data-field="setRule"
        editor-type="dxCheckBox"
        :disabled="true"
      >
        <DxLabel :text="getTranslate('ReusableLookupLabel')" />
      </DxSimpleItem>
    </DxGroupItem>
  </dxform>
  <DxToolbar>
    <DxItem location="after">
      <DxButton
        name="nextButton"
        :text="getTranslate('ReusableCancelButtonLabel')"
        @click="onCancelClick()"
      />
    </DxItem>
    <DxItem location="after">
      <DxButton
        name="nextButton"
        type="success"
        :text="getTranslate('ReusableOKButtonLabel')"
        @click="saveMapping()"
      />
    </DxItem>
  </DxToolbar>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxLabel
} from "devextreme-vue/form";
import { formatMessage } from "devextreme/localization";
import DxButton from "devextreme-vue/button";
import store from "../../store";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxAutocomplete } from "devextreme-vue/autocomplete";

export default {
  name: "MappingRules",
  components: {
    DxItem,
    DxButton,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxLabel,
    DxAutocomplete,
    DxToolbar,
  },
  props:  {
    selectedParameterProp: {
      type: Object,
      required: true,
    },
    parametersProp: {
      type: Array,
      required: true,
    }
  },
  emits: ["saveMappingToParameter", "onCancelClick"],
  data() {
    return {
      dataTypes: ["String", "Int32", "Int64", "Decimal", "DateTime", "Bool"],
      mappingObject: {
        name: null,
        required: null,
        type: "String",
        unique: null,
        fmGuid: null,
      },
      parameter: {},
      assetParameters: [],
      assetParametersNames: [],
    }
  },
  watch: {
    selectedParameterProp: {
      immediate: true,
      handler (parameter) {
        this.parameter = parameter;
        if (Object.prototype.hasOwnProperty.call(parameter,"mapping")) {
          this.mappingObject = parameter.mapping;
          this.mappingObject.type = this.dataTypes[parameter.mapping.dataType];
        } else {
          this.mappingObject = {
            name: null,
            required: null,
            type: "String",
            unique: null,
            fmGuid: null,
          }
        }
      }
    },
    parametersProp: {
      immediate: true,
      handler (parameters) {
        this.assetParameters = parameters;
        this.assetParametersNames = parameters.map((assetParameter) => assetParameter.parameter.name);
      }
    }
  },
  methods: {
    returnPlaceholder(placeholderName) {
      return { placeholder: formatMessage(placeholderName) };
    },
    getTranslate(text) {
      return formatMessage(text);
    },
    saveMapping() {
      this.parameter.mapping = this.mappingObject;
      if (this.mappingObject.isFmGuid === true) {
        store.dispatch("setFMGuidParameter", this.parameter);
      }
      this.$emit("saveMappingToParameter", this.parameter);
    },
    isFMGuidDisabled(parameterId) {
      const fmGuidParameter = store.getters.getFMGuidParameter;
      if (Object.prototype.hasOwnProperty.call(fmGuidParameter, "mapping")
        && fmGuidParameter.parameterId !== parameterId
        && fmGuidParameter.mapping.fmGuid === true) {
        return true;
      }
      return false;
    },
    isSet(mappingObject) {
      return !!mappingObject?.setRule;
    },
    valueChanged() {
      const matchedParameter = this.assetParameters.find((parameter) => parameter.parameter.name === this.mappingObject.name);
      if (matchedParameter !== undefined) {
        this.mappingObject.type = this.dataTypes[matchedParameter.parameter.dataType];
        let setRule = matchedParameter?.parameter?.rule
        if (setRule) {
          this.mappingObject.unique = false;
          this.mappingObject.fmGuid = false;
        }
        this.mappingObject.setRule = setRule;
        this.mappingObject.setRule?.ruleValues.forEach(rv => rv.ruleValueId = undefined);
      } else {
        this.mappingObject.type = "String";
      }
    },
    onCancelClick() {
      this.$emit("onCancelClick");
    }
  }
}
</script>
<style scoped>
  #mappingForm div.dx-button-mode-contained {
    margin: 0px !important;
  }
  #mappingName .dx-fieldset {
    margin-left: 0px;
  }
  #mappingName .dx-field-label {
    width: 100px;
    position: absolute;
    top: -20px;
  }
  #mappingName .dx-field-value {
    border: 1px solid #e0e0e0;
    border-radius: 0.4em;
    max-width: none;
  }
</style>
