<template>
  <div class="widget-container">
    <div>
      <div class="list-header-container">
        {{ getTranslate("AvailableCategories") }}:
        {{ availableCategories.length }}
        <DxButton
          class="add-categories-btn"
          name="addCategories"
          icon="chevrondoubleright"
          type="success"
          :disabled="!checkedAvailableCategories.length"
          @click="addCategories()"
        />
      </div>
      <DxList
        v-model:selected-item-keys="checkedAvailableCategories"
        class="list"
        :data-source="availableCategories"
        :repaint-changes-only="true"
        key-expr="categoryId"
        display-expr="categoryName"
        :search-enabled="true"
        :search-mode="searchMode"
        search-expr="categoryName"
        :show-selection-controls="true"
        :selection-mode="selectionMode"
        :select-all-mode="selectAllMode"
        page-load-mode="nextButton"
        :no-data-text="labels.noDataInListLabel"
      >
        <DxItemDragging
          data="availableCategories"
          :allow-reordering="false"
          :on-drag-start="onDragStart"
          :on-add="onAdd"
          :on-remove="onRemove"
          group="tasks"
        />
      </DxList>
    </div>
    <div class="selected-categories-div">
      <div class="list-header-container reverse">
        {{ getTranslate("SelectedCategories") }}:
        {{ selectedCategories.length }}
        <DxButton
          class="remove-categories-btn"
          name="removeCategories"
          icon="chevrondoubleleft"
          type="danger"
          :disabled="!checkedSelectedCategories.length"
          @click="removeCategories()"
        />
      </div>
      <DxList
        v-model:selected-item-keys="checkedSelectedCategories"
        class="list-selected"
        :data-source="selectedCategories"
        :repaint-changes-only="true"
        key-expr="categoryId"
        display-expr="categoryName"
        :search-enabled="true"
        :search-mode="searchMode"
        search-expr="categoryName"
        :show-selection-controls="true"
        :selection-mode="selectionMode"
        :select-all-mode="selectAllMode"
        :no-data-text="labels.noDataInListLabel"
      >
        <DxItemDragging
          data="selectedCategories"
          :allow-reordering="false"
          :on-drag-start="onDragStart"
          :on-add="onAdd"
          :on-remove="onRemove"
          group="tasks"
        />
      </DxList>
    </div>
  </div>
</template>
<script>
import DxList, { DxItemDragging } from "devextreme-vue/list";
import DxButton from "devextreme-vue/button";
import { formatMessage } from "devextreme/localization";
import store from "../../store";

export default {
  name: "CreateProject",
  components: {
    DxList,
    DxItemDragging,
    DxButton,
  },
  props: {
    availableCategoriesProp: {
      type: Array,
      required: true,
    },
    selectedCategoriesProp: {
      type: Array,
      required: true,
    },
  },
  emits: ["input", "changedSelectedCategories"],
  data() {
    return {
      availableCategories: [],
      allCategories: [],
      selectedCategories: [],
      selectionMode: "all",
      selectAllMode: "allPages",
      searchMode: "contains",
      dataSource: [],
      checkedAvailableCategories: [],
      checkedSelectedCategories: [],
      labels: {
        noDataInListLabel: formatMessage("ReusableNoDataInListLabel"),
      },
    };
  },
  watch: {
    availableCategoriesProp(newValue) {
      store.dispatch("setLoader", false);
      this.availableCategories = newValue;
      this.allCategories = newValue;
    },
    selectedCategoriesProp(newValue) {
      const selectedCategoriesGuids = newValue.map(
        (selectedCategory) => selectedCategory.categoriesSelectedId
      );
      this.availableCategories = this.allCategories.filter(
        (category) => !selectedCategoriesGuids.includes(category.categoryId)
      );
      this.selectedCategories = this.allCategories.filter((category) =>
        selectedCategoriesGuids.includes(category.categoryId)
      );
      this.$emit("changedSelectedCategories", this.selectedCategories);
    },
  },
  methods: {
    addCategories() {
      this.availableCategories.forEach((category) => {
        if (this.checkedAvailableCategories.includes(category.categoryId)) {
          this.selectedCategories = [...this.selectedCategories, category];
        }
      });
      this.availableCategories = this.availableCategories.filter(
        (availableCategory) =>
          !this.checkedAvailableCategories.includes(
            availableCategory.categoryId
          )
      );
      this.$emit("changedSelectedCategories", this.selectedCategories);
    },
    removeCategories() {
      this.selectedCategories.forEach((category) => {
        if (this.checkedSelectedCategories.includes(category.categoryId)) {
          this.availableCategories = [...this.availableCategories, category];
        }
      });
      this.selectedCategories = this.selectedCategories.filter(
        (selectedCategory) =>
          !this.checkedSelectedCategories.includes(selectedCategory.categoryId)
      );
      this.$emit("changedSelectedCategories", this.selectedCategories);
    },
    onDragStart(e) {
      e.itemData = this[e.fromData][e.fromIndex];
    },
    onAdd(e) {
      const data = [...this[e.toData]];
      data.splice(e.toIndex, 0, e.itemData);
      this[e.toData] = data;
    },
    onRemove(e) {
      const data = [...this[e.fromData]];
      data.splice(e.fromIndex, 1);
      this[e.fromData] = data;
    },
    getTranslate(text) {
      return formatMessage(text);
    },
  },
};
</script>
<style scoped>
.widget-container {
  display: flex;
  max-width: 800px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
.widget-container > * {
  height: auto;
  width: 50%;
  padding: 10px;
}
.dx-scrollview-content {
  min-height: 400px;
}
.add-categories-btn {
  margin: 0px;
  float: right;
}
.remove-categories-btn {
  margin: 0px;
  float: left;
}
.list-header-container {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.reverse {
  flex-direction: row-reverse;
}
.float-right {
  float: right;
}
.width-100 {
  width: 100%;
}
.list {
  max-height: 340px !important;
}
.dx-list-item-content {
  font-size: 14px;
  padding: 12px 12px 10px;
}
.dx-list-item-before-bag.dx-list-select-checkbox-container,
.dx-list-item-before-bag.dx-list-select-radiobutton-container {
  widows: 46px;
}
.selected-categories-div {
  position: relative;
  border-left: 1px solid #dddddd;
}
.list-selected {
  max-height: 340px;
}
</style>
